import React from "react";
import PropTypes from "prop-types";

import { Badge } from "shipnow-mercurio";

function PercentageBadge({ numerator, denominator }) {
  if (parseInt(denominator) === 0 || numerator === denominator) {
    return null;
  }

  const _numerator = parseFloat(numerator);
  const _denominator = parseFloat(denominator);

  const percentage = Math.trunc(
    ((_denominator - _numerator) * 100) / _denominator
  );

  if (percentage > 0) {
    return (
      <Badge size="md" color="success">
        {percentage}% OFF
      </Badge>
    );
  } else {
    return (
      <Badge size="md" color="error">
        +{-percentage}%
      </Badge>
    );
  }
}

PercentageBadge.propTypes = {
  numerator: PropTypes.string,
  denominator: PropTypes.string,
};

PercentageBadge.defaultProps = {
  numerator: null,
  denominator: false,
};

export default PercentageBadge;
