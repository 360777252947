import { Store } from "../lib";
import { Tracker } from "../models";

export default class TrackerStore extends Store {
  model = Tracker;

  get(uid, secure = false, token, forceRefresh = false, onFetch = null) {
    const filters = token ? { uid, token } : { uid };
    const ModelClass = this.model;
    let item = this.items.find(uid);

    if (!item) {
      item = new ModelClass({ id: uid, order: undefined, events: [] }, this);
      this.items.add(item);
    }
    if (forceRefresh || item.needsUpdate()) {
      item.beginUpdate();
      this.adapter
        .search(this.modelRoot, filters, secure)
        .then(
          (res) => {
            if (secure) {
              this.adapter
                .search(`/orders/${res.results.order.id}/messages`)
                .then((_res) => {
                  res["results"]["id"] = uid;
                  res["results"]["messages"] = _res.results;
                  this.items.addOrUpdateModel(
                    new ModelClass(res["results"] || res, this)
                  );
                  item.endUpdate();
                  onFetch && onFetch(item);
                })
                .catch((err) => {
                  res["results"]["id"] = uid;
                  res["results"]["messages"] = [];
                  this.items.addOrUpdateModel(
                    new ModelClass(res["results"] || res, this)
                  );
                  item.endUpdate();
                  onFetch && onFetch(item);
                });
            } else {
              res["results"]["id"] = uid;
              this.items.addOrUpdateModel(
                new ModelClass(res["results"] || res, this)
              );
              item.endUpdate();
              onFetch && onFetch(item);
            }
          },
          (err) => {
            item.endUpdate(err);
            onFetch && onFetch(item);
          }
        )
        .catch((err) => {
          item.endUpdate(err);
          onFetch && onFetch(item);
        });
    }
    return item;
  }
}
