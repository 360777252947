import * as yup from "yup";

export const warehouseAddressSchema = yup.object().shape({
  check: yup.bool(),
  city: yup.string().required("Ingresa la localidad o barrio."),
  floor: yup.string().nullable(),
  state: yup.string().required(),
  street_name: yup.string().required("Ingresa la calle."),
  street_number: yup
    .string()
    .test(
      "valid",
      'Ingresa un número o selecciona "sin número".',
      (value, context) => {
        const { check } = context.options.parent;
        return check || (!check && value);
      }
    ),
  unit: yup.string().nullable(),
  zip_code: yup
    .string()
    .length(4, "Ingresa 4 dígitos.")
    .required("Ingresa 4 dígitos."),
});
