import { observable, computed } from "mobx";

export default class RESTBatcher {
  @observable _status; // empty | busy | ok | error
  _currentModel = null;
  _failedList = [];
  _completedList = [];
  _toProcessQueue;
  _methodName;

  _onUpdateCallback;

  constructor(modelsArray, methodName = "save") {
    this._status = "empty";
    this._toProcessQueue = modelsArray;
    this._methodName = methodName;
  }

  processQueue() {
    this._status = "busy";

    if (this._toProcessQueue.length === 0) {
      this._status = this.failedCount > 0 ? "error" : "ok";

      if (this._onUpdateCallback) {
        this._onUpdateCallback(this);
        this._onUpdateCallback = null;
      }

      return this;
    }

    this._currentModel = this._toProcessQueue.shift();

    const request = this._currentModel[this._methodName]();

    const isAndThen = !!request["andThen"];

    request[isAndThen ? "andThen" : "then"](
      isAndThen ? this.andThenResolve : this.thenResolve,
      isAndThen ? undefined : this.thenReject
    );

    return this;
  }

  andThenResolve = (res, _responseError) => {
    if (_responseError) {
      this._failedList.push(res);
    } else {
      this._completedList.push(res);
    }
    this.processQueue();
  };

  thenResolve = (res) => {
    this._completedList.push(res);
    this.processQueue();
  };

  thenReject = (res) => {
    this._failedList.push(res);
    this.processQueue();
  };

  retryFailedList() {
    return this;
  }

  @computed
  get toProcessCount() {
    return this._toProcessQueue.length;
  }

  @computed
  get failedCount() {
    return this._failedList.length;
  }

  @computed
  get completedCount() {
    return this._completedList.length;
  }

  get failedList() {
    return this._failedList;
  }

  get CompletedList() {
    return this._completedList;
  }

  isEmpty() {
    return this._status === "empty";
  }

  isBusy() {
    return this._status === "busy";
  }

  isOk() {
    return this._status === "ok";
  }

  isError() {
    return this._status === "error";
  }

  andThen(_callback) {
    if (this.isOk()) {
      _callback && _callback(this);
      return this;
    }

    this._onUpdateCallback = _callback;

    return this;
  }
}
