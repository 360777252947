export const IS_DEVELOPMENT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const DOMAIN_LOCALHOLST = "localhost";
export const DOMAIN_SHIPNOW = "shipnow.com.ar";

const URL_LOCALHOLST = process.env.REACT_APP_URL || "http://localhost:3000";
const URL_SHIPNOW = "https://app.shipnow.com.ar";

export const URL = IS_DEVELOPMENT ? URL_LOCALHOLST : URL_SHIPNOW;

export const DATABASE_URL = process.env.REACT_APP_SHIPNOW_CORE_URL.includes(
  "localhost"
)
  ? "Local"
  : "Producción";
