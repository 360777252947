import { Store } from "../lib";
import { User } from "../models";

export default class UsersStore extends Store {
  model = User;
  baseWebURI = "/app/users";

  validateConfirmationMail() {
    return this.appStore.coreAPIClient.sendRequest(`/users/me`, "GET");
  }

  resendConfirmationEmail(expired_token) {
    if (expired_token) {
      return this.appStore.coreAPIClient.sendRequest(
        `/users/resend_confirmation`,
        "POST",
        { confirmation_token: expired_token },
        false
      );
    } else {
      return this.appStore.coreAPIClient.sendRequest(
        `/users/resend_confirmation`,
        "POST"
      );
    }
  }
}
