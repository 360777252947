import React from "react";
import { Flex } from "shipnow-mercurio";
import PropTypes from "prop-types";
const PageHeaderLayout = ({ children, justifyContent, margin }) => {
  return (
    <Flex justifyContent={justifyContent} margin={margin} alignItems="end">
      {children}
    </Flex>
  );
};
PageHeaderLayout.propTypes = {
  justifyContent: PropTypes.string,
  margin: PropTypes.string,
};
PageHeaderLayout.defaultProps = {
  justifyContent: "space-between",
  margin: "b-24",
};

export default PageHeaderLayout;
