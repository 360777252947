export default class SessionStorageClient {
  namespace = "";

  constructor(namespace) {
    this.namespace = namespace;
  }

  errorMensaje() {
    console.warn(
      "SHIPNOW_DEV: Para una mejor experiencia habilitar permisos de cookies."
    );
  }

  get(path, id = null) {
    let stored;

    try {
      stored = sessionStorage.getItem(
        `${this.namespace}_${path}_${id ? id : "default"}`
      );
    } catch {
      this.errorMensaje();
    }

    return stored;
  }

  post(path, item) {
    let toStore = JSON.stringify(item);
    const id = item && item.id;

    try {
      sessionStorage.setItem(
        `${this.namespace}_${path}_${id ? id : "default"}`,
        toStore
      );
    } catch {
      this.errorMensaje();
    }

    return item;
  }

  put(path, item) {
    return this.post(path, item);
  }

  delete(path, id) {
    try {
      sessionStorage.removeItem(`${this.namespace}_${path}_${id || "default"}`);
    } catch {
      this.errorMensaje();
    }

    return id;
  }
}
