import React from "react";
import { useField } from "formik";
import { Select } from "shipnow-mercurio";

export const SelectFormik = (props) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  return (
    <Select
      notFormik={false}
      {...props}
      {...field}
      onBlur={(e) => {
        field.onBlur(e);
        props.onBlur && props.onBlur(e);
      }}
      onChange={(e) => {
        helpers.setValue(e);
        props.onChange &&
          props.onChange({ target: { value: e, name: field.name } });
      }}
    />
  );
};
