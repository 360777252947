/**
  displays an error message in the browser console when access to local storage is not 
  enabled.
*/
const localStorageConsoleError = () => {
  console.warn(
    "Please enable cookie permissions for a better user experience."
  );
};

/**
  searches for a key in the local storage of the browser. If the key is found, its 
  value is returned. If an error occurs while accessing the local storage, an error 
  message is logged to the browser console.
  @param {string} key - key to search.
  @return {string} value of the searched key.
*/
export const getLocalStorageValue = (key) => {
  try {
    return localStorage.getItem(key);
  } catch {
    localStorageConsoleError();
  }
};

/**
  removes a key from the local storage of the browser. If an error occurs while accessing
  the local storage, an error message is logged to the browser console.
  @param {string} key - key to delete.
*/
export const deleteLocalStorageValue = (key) => {
  try {
    localStorage.removeItem(key);
  } catch {
    localStorageConsoleError();
  }
};

/**
  adds a key and value to the local storage of the browser. If an error occurs while accessing the local 
  storage, an error message is logged to the browser console.
  @param {string} key - key to add.
  @param {string} value - value to add.
*/
export const setLocalStorageValue = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    localStorageConsoleError();
  }
};
