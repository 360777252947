import moment from "moment";

import { REGEX_DATE_FORMAT } from "../assets/constants/regexs";

/**
 * Gets the date in "DD/MM" format from a given date.
 * @param {string|Date} date - The date in string format or a Date object.
 * @returns {string} The date in "DD/MM" format.
 */
//TODO: Esto debería quedar deprecado por el nuevo componente de fechas.
export const getDateText = (date) => {
  return moment(date).format("DD/MM");
};

/**
 * Generates a formatted text representing a scheduled date range.
 *
 * @param {string} from_date - The start date.
 * @param {string} to_date - The end date.
 * @param {string} date_format - The desired format for representing the date. (Default: "DD/MM/YYYY")
 * @returns {string} - Formatted text describing the scheduled date range. Returns an empty string if input dates are falsy.
 */
//TODO: Esto debería quedar deprecado por el nuevo componente de fechas.
export const scheduledDateText = (from_date, to_date, date_format) => {
  if (!from_date || !to_date) return "";

  const dateFor = {
    date: moment(from_date).format(date_format || "DD/MM/YYYY"),
    fromHour: moment(from_date).format("HH:mm"),
    toHour: moment(to_date).format("HH:mm"),
  };

  return `${dateFor.date}, de ${dateFor.fromHour} a ${dateFor.toHour}`;
};

/**
 * Sorts two date values in ascending or descending order.
 *
 * @param {string | Date} timeA - The first date value.
 * @param {string | Date} timeB - The second date value.
 * @param {string} [orderBy="desc"] - The order in which to sort the dates ("asc" for ascending, "desc" for descending).
 * @returns {number} - Returns a negative number if timeA should come before timeB, a positive number if timeA should come after timeB, or 0 if they are equal.
 */
export const sortByDate = (timeA, timeB, orderBy = "desc") => {
  if (!timeA) {
    return 1;
  } else if (!timeB) {
    return -1;
  } else if (moment(timeA).isAfter(timeB)) {
    return orderBy === "desc" ? -1 : 1;
  } else if (moment(timeA).isBefore(timeB)) {
    return orderBy === "desc" ? 1 : -1;
  } else {
    return 0;
  }
};

/**
 * Formats a given date string to ISO 8601 format if the date is valid.
 * @param {string} dateString - The date string to format.
 * @returns {string|null} - The formatted date in ISO 8601 format if valid, otherwise null.
 */
export const formatTimestampDate = (dateString) => {
  const formattedDate = dateString.replace(REGEX_DATE_FORMAT, "-");

  const date = moment(formattedDate, "DD-MM-YYYY");

  return date.isValid() ? date.toISOString() : dateString;
};

/**
 * Checks if the given key name represents a date field.
 * The function checks if the key name ends with "_for" or "_at", which are
 * indicators of date fields.
 * @param {string} keyName - The key name to check.
 * @returns {boolean} True if the key name ends with "_for" or "_at", false otherwise.
 */
export const isDateField = (keyName) => {
  if (typeof keyName !== "string") {
    return false;
  }

  return keyName.endsWith("_for") || keyName.endsWith("_at");
};

/**
 * Formats a given date and time into the "DD/MM/YYYY, HH:mm" format.
 *
 * @param {Date} _date - a string in a format accepted by moment.js
 * @returns {string} The formatted date and time in the "DD/MM/YYYY, HH:mm" format.
 */
//TODO: Esto debería quedar deprecado por el nuevo componente de fechas.
export const getFormatedDate = (_date) => {
  const date = moment(_date).format("DD/MM/YYYY");
  const hour = moment(_date).format("HH:mm");

  return `${date}, ${hour}`;
};

/**
 * Retrieves the estimated shipping date text based on the start and end dates.
 *
 * @param {string} from_date - The start date of the shipment.
 * @param {string} to_date - The end date of the shipment.
 * @returns {string} The text indicating the estimated shipping date.
 */
export const getShippingDateText = (from_date, to_date) => {
  if (!from_date && !to_date) {
    return "Sin entrega estimada";
  } else {
    const minDate = getDateText(moment(from_date));
    const maxDate = getDateText(moment(to_date));
    const currentDay = getDateText(moment());

    if (minDate === maxDate) {
      //? la entrega es hoy.
      if (minDate === currentDay) {
        const minHour = moment(from_date).format("HH:mm");
        const maxHour = moment(to_date).format("HH:mm");

        return `Podría llegar entre las ${minHour} y las ${maxHour}`;
      } else {
        return `Llega el ${scheduledDateText(from_date, to_date, "DD/MM")}`;
      }
    } else {
      return `Podría llegar entre el ${minDate} y el ${maxDate}`;
    }
  }
};
