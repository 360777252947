import { Store } from "../lib";
import { Invite } from "../models";

export default class InvitesStore extends Store {
  model = Invite;

  searchByCode(invite_code) {
    return this.appStore.coreAPIClient.sendRequest(
      `/invites?code=${invite_code}`,
      "GET",
      null,
      false,
      null
    );
  }
}
