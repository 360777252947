export const VENDEDOR = "Vendedor";
export const ENVIO = "Envío";
export const DEVOLUCION = "Devolución";
export const PREPARACION = "Preparación";
export const COMPRADOR = "Comprador";
export const DESTINO = "Destino";
export const MODALIDAD = "Modalidad";
export const ORIGEN = "Origen";
export const FIN = "Fin";

export const BUYER_FIELDS = [
  "doc_number",
  "doc_type",
  "email",
  "full_name",
  "last_name",
  "name",
  "phone",
];
