import { Store } from "../lib";
import { Charge } from "../models";

export default class ChargesStore extends Store {
  model = Charge;

  monthlyStats(account_id) {
    return this.fetch(
      "stats",
      {
        invoiced: false,
        seller_id: account_id,
      },
      null,
      true
    );
  }
}
