import React from "react";

import { FAQLink } from "./FAQLink";

import { FAQS_IIBB } from "../../../assets/constants/links";

export const IIBBDownloadFAQ = () => {
  return (
    <FAQLink
      button={{
        id: "conocer_mas_datos_facturacion_iibb_ga4",
        href: FAQS_IIBB,
      }}
      margin="t-24"
      text="cómo descargar el comprobante de inscripción a IIBB."
    />
  );
};
