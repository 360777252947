import { Store } from "../lib";

import { Order, ReturnsGroup } from "../models";

export default class ReturnsGroupsStore extends Store {
  model = ReturnsGroup;
  baseWebURI = "/app/returns/returns-group";

  getActiveReturnsGroup(filters) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient
      .sendRequest(`/return_groups/active?${searchString}`, "GET", {}, true)
      .then((res) => {
        return res.results.id ? new ReturnsGroup(res.results) : null;
      });
  }

  getDestinationStatsList(filters) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient.sendRequest(
      `/check_ins/destination_stats?${searchString}`,
      "GET"
    );
  }

  getDestinationStats() {
    return this.appStore.coreAPIClient.search(`/check_ins/destination_stats`);
  }

  getOrdersByReturnGroup(filters) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient
      .sendRequest(`/return_group_items?${searchString}`, "GET", {}, true)
      .then((res) => {
        res.results = res.results.map((item) => {
          const order = new Order(item.order, this.appStore.orders);
          order._status = "ok";
          return order;
        });

        return res;
      });
  }

  getReturnsGroup(id, filters) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient
      .sendRequest(`/return_groups/${id}?${searchString}`, "GET", {}, true)
      .then((res) => {
        res.results = new ReturnsGroup(res.results);
        return res;
      });
  }

  getReturnsGroupList(filters) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient
      .sendRequest(`/return_groups?${searchString}`, "GET", {}, true)
      .then((res) => {
        res.results = res.results.map((item) => {
          const returnGroup = new ReturnsGroup(item);
          returnGroup._status = "ok";
          return returnGroup;
        });

        return res;
      });
  }

  getStats(filters) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient
      .sendRequest(`/return_groups/stats?${searchString}`, "GET", {}, true)
      .then((res) => {
        return res.results;
      });
  }

  post(params) {
    return this.appStore.coreAPIClient
      .sendRequest("/return_groups", "POST", params)
      .then((res) => {
        return res.results;
      });
  }
}
