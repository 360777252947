const DEFAULT_ATTRIBUTES = {
  accepted_tariff: false,
  active: false,
  active_services: [],
  average_volume: 0,
  balance: 0,
  billing_email: "",
  business_name: "",
  deactivate_reason: null,
  dnd: false,
  doc_number: null,
  doc_type: null,
  email: null,
  fiscal_category: "",
  fiscal_industries_ids: [],
  fiscal_taxes: [],
  hero_image_url: null,
  hubspot_id: null,
  iibb_url: null,
  image_url: null,
  insurance_policy_type: "",
  insurance_policy_value: 0,
  last_name: null,
  name: null,
  onboarding: {
    accepted_operation_constraints: false,
    avg_variant_type: [],
    industry: null,
    location: null,
    monthly_orders: null,
    valid_location: false,
    variant_avg_height: null,
    variant_avg_length: null,
    variant_avg_width: null,
  },
  payment_modality: "",
  phone: null,
  picking_preference_own: "",
  send_stock_email: true,
  support_type: null,
  test: false,
  url: "",
  valid_fiscal_industry: false,
  salesforce_id: null,
};

export class Account {
  constructor(attributes) {
    const attrs = Object.assign({}, DEFAULT_ATTRIBUTES, attributes);

    Object.keys(attrs).forEach((key) => {
      this[key] = attrs[key];
    });
  }

  get isShipnow() {
    return this.id === 1;
  }

  //? account types
  get isFullfilment() {
    return this.picking_preference_own === "warehouse";
  }

  get isCrossdocking() {
    return this.picking_preference_own === "cross_docking";
  }

  //? account features
  get hasChatSupport() {
    return this.support_type === "chat" && !this.isShipnow;
  }

  //? account status
  get isWaitingOnboarding() {
    return this.deactivate_reason === "waiting_for_onboarding";
  }
}
