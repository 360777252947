import { Model } from "../lib";
import { action } from "mobx";

export default class ShipimentItem extends Model {
  urlRoot = "/shipment_items";
  currentShipment;
  currentOrder;

  @action
  afterSetData() {
    if (!this.currentShipment && this._store && this.shipment) {
      this.currentShipment = this._store.appStore.shipments.getFromData(
        this.shipment
      );
      this.currentShipment._status = "ok";
    }

    if (
      !this.currentOrder &&
      this._store &&
      this.shipment &&
      this.shipment.order
    ) {
      this.currentOrder = this._store.appStore.orders
        .getFromData(this.shipment.order)
        .clone();
      this.currentOrder._status = "ok";
    }
  }
}
