import React from "react";

import { useRouter } from "../../../hooks/useRouter";

import { ReactComponent as NotFoundError } from "../../../assets/images/mercurio_error_404.svg";
import { MessagePageStruct } from "../";

const Error404 = () => {
  const router = useRouter();

  const handleClick = () => {
    router.push("/app");
  };

  return (
    <>
      <MessagePageStruct
        buttonText="Ir al inicio"
        image={
          <NotFoundError
            style={{
              height: "300px",
              width: "100%",
            }}
          />
        }
        onClick={handleClick}
        title="¡Ups!"
        text="La página que estás buscando no existe o fue removida."
      />
    </>
  );
};

export default Error404;
