import { Model } from "../lib";
import { computed, action } from "mobx";

export default class GoodsInRecurrence extends Model {
  constructor(attributes, store) {
    let defaultAttributes = {
      from_warehouse_id: null,
      to_warehouse_id: null,
      from_hour: null,
      resource_type: null,
      to_hour: null,
      type: null,
      weekdays: [],
      shipping_category: null,
      seller_id: null,
    };

    super(attributes, store, defaultAttributes);
  }
  urlRoot = "/goods_in_recurrences";

  @action
  afterSetData() {
    if (this.from_warehouse) {
      this.from_warehouse_id = this.from_warehouse.id;
    }
  }

  @computed
  get isOpen() {
    return this.weekdays ? false : true;
  }

  @computed
  get isEveryday() {
    return (
      this.weekdays &&
      this.weekdays.includes(1) &&
      this.weekdays.includes(2) &&
      this.weekdays.includes(3) &&
      this.weekdays.includes(4) &&
      this.weekdays.includes(5)
    );
  }
}
