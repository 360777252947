import { observable, action } from "mobx";

import Collection from "./Collection";
import DataWrapper from "./DataWrapper";

import { hashCode } from "./helpers";

export default class Store {
  @observable items = new Collection(this); // all items in the store
  @observable views = new Map(); // views contain Collections of items according to UI needs
  @observable contextData = new Map(); // contextData contextual information accoording to UI needs
  @observable fetchResults = new Map(); // stores model-unrelated results; stores DataWrappers

  model = null;
  appStore = null;
  adapter = null;
  urlRoot = null;
  elasticRoot = "search"; // custom elactic search endpoint

  updateThreshold = 2; // minutes -- make this 5

  constructor(adapter, appStore) {
    this.adapter = adapter;
    this.appStore = appStore;

    if (appStore && appStore.settings && appStore.settings.refreshRate) {
      this.updateThreshold = this.appStore.settings.refreshRate;
    }
  }

  get modelRoot() {
    if (this.urlRoot) return this.urlRoot;

    const ModelClass = this.model;
    this.urlRoot = new ModelClass({}, this).urlRoot;

    return this.urlRoot;
  }

  view(viewName) {
    let view;

    if (!this.views.has(viewName)) {
      view = new Collection(this, viewName);
      this.views.set(viewName, view);
    } else {
      view = this.views.get(viewName);
    }

    return view;
  }

  viewData(viewName) {
    let viewData;

    if (!this.contextData.has(viewName)) {
      viewData = observable(new Map());
      this.contextData.set(viewName, viewData);
    } else {
      viewData = this.contextData.get(viewName);
    }
    return viewData;
  }

  fetchResult(viewName, defaultData) {
    let view;

    if (!this.fetchResults.has(viewName)) {
      view = new DataWrapper(defaultData, this);
      this.fetchResults.set(viewName, view);
    } else {
      view = this.fetchResults.get(viewName);
    }

    return view;
  }

  getDummy(length = 1) {
    const ModelClass = this.model;
    if (length <= 1) {
      return new ModelClass({}, this);
    } else {
      return Array.from(Array(length)).map(() => new ModelClass({}, this));
    }
  }

  getNew(attrs = {}) {
    const ModelClass = this.model;
    return new ModelClass(attrs, this);
  }

  @action
  getAll(limit = 1000) {
    return this.search({ per_page: limit }, "all");
  }

  @action
  search(filters, viewName = "default", forceRefresh = false, apiPath = null) {
    const viewFullName = `${viewName}-${hashCode(JSON.stringify(filters))}-${
      this.appStore && this.appStore.loggedInUserKey
    }`;

    const view = this.view(viewFullName);
    const viewData = this.viewData(viewFullName);

    const url = `${apiPath || this.modelRoot}`;

    if (forceRefresh || view.needsUpdate()) {
      view.beginUpdate();
      const ModelClass = this.model;

      this.adapter.search(url, filters).then(
        (res) => {
          view.clear();
          viewData.clear();

          const items = res["results"] || res;
          const contextData = res["headers"] || res;

          if (Array.isArray(items)) {
            for (var i = 0, l = items.length; i < l; i++) {
              view.add(
                this.items.addOrUpdateModel(new ModelClass(items[i], this))
              );
            }
          } else {
            view.add(this.items.addOrUpdateModel(new ModelClass(items, this)));
          }

          viewData.merge(contextData);

          view.endUpdate();

          return view;
        },
        (err) => {
          view.endUpdate(err);
        }
      );
    }

    return view;
  }

  @action
  elasticSearch(
    filters,
    viewName = "default",
    forceRefresh = false,
    apiPath = null
  ) {
    const url = `${apiPath || this.modelRoot}/${this.elasticRoot}`;

    return this.search(filters, viewName, forceRefresh, url);
  }

  @action
  get(id, forceRefresh = false, onFetch = null, filters, apiPath = null) {
    const ModelClass = this.model;
    let item = this.items.find(id);

    if (!item) {
      item = new ModelClass({ id: id }, this);

      this.items.add(item);
    }

    if (forceRefresh || item.needsUpdate()) {
      item.beginUpdate();
      this.adapter.get(apiPath || this.modelRoot, id, filters).then(
        (res) => {
          this.items.addOrUpdateModel(
            new ModelClass(res["results"] || res, this)
          );
          item.endUpdate();

          onFetch && onFetch(item);
        },
        (err) => {
          item.endUpdate(err);
        }
      );
    } else {
      onFetch && onFetch(item);
    }

    return item;
  }

  @action
  getFromData(itemData) {
    const ModelClass = this.model;

    let item = new ModelClass(itemData, this);
    item._status = "ok";

    return item;
  }

  @action
  getFromStoredData(itemData) {
    let item = this.items.find(itemData.id);

    if (!item) {
      const ModelClass = this.model;
      item = new ModelClass(itemData, this);
      item._status = "ok";

      this.items.add(item);
    }

    return item;
  }

  @action
  save(model, apiPath = null, secure = true, filters = {}) {
    model.beginUpdate();

    if (model.isNew) {
      this.adapter.post(apiPath || this.modelRoot, model, secure, filters).then(
        (res) => {
          if (res && res["results"]) {
            model.set(res["results"]);
            this.items.addOrUpdateModel(model);
            model.endUpdate();
          }
        },
        (err) => {
          model.endUpdate(err);
        }
      );
    } else {
      this.adapter
        .put(apiPath || this.modelRoot, model, null, secure, filters)
        .then(
          (res) => {
            if (res && res["results"]) {
              model.set(res["results"]);
              this.items.addOrUpdateModel(model);
              model.endUpdate();
            }
          },
          (err) => {
            model.endUpdate(err);
          }
        );
    }
    return model;
  }

  @action
  destroy(model, apiPath = null) {
    model.beginUpdate();

    this.adapter.delete(apiPath || this.modelRoot, model.id).then(
      (res) => {
        this.items.addOrUpdateModel(model);
      },
      (err) => {
        model.endUpdate(err);
      }
    );
  }

  @action
  fetch(
    action,
    params,
    defaultData = {},
    forceRefresh = false,
    apiPath = null,
    secure = true
  ) {
    const viewFullName = `${action}-${hashCode(JSON.stringify(params))}-${
      this.appStore && this.appStore.loggedInUserKey
    }`;
    let view = this.fetchResult(viewFullName, defaultData);

    if (forceRefresh || view.needsUpdate()) {
      const url = apiPath ? apiPath : this.modelRoot;

      view.beginUpdate();
      this.adapter.search(`${url}/${action}`, params, secure).then(
        (res) => {
          // get the raw data
          const data = res["results"] || res;

          view.set(data);

          view.endUpdate();
        },
        (err) => {
          view.endUpdate(err);
          this.fetchResults.delete(viewFullName);
        }
      );
    }

    return view;
  }

  @action
  store(json) {
    const ModelClass = this.model;
    let item = this.items.find(json.id);

    if (!item) {
      item = new ModelClass({ id: json.id }, this);

      this.items.add(item);
    }

    item.beginUpdate();

    this.items.addOrUpdateModel(new ModelClass(json, this));

    item.endUpdate();

    return item;
  }

  @action
  clear() {
    this.items.clear();

    this.views.forEach((view, key) => {
      view.clear();
    });
    this.views.clear();
  }
}
