import * as Sentry from "@sentry/react";
import { PROJECT_ID } from "../assets/constants/settings";

export const useSentry = () => {
  // Inicialización de Sentry
  const initSentry = () => {
    if (window.location.hostname !== "localhost") {
      Sentry.init({
        dsn: `https://${process.env.REACT_APP_SENTRY_KEY}.ingest.us.sentry.io/${PROJECT_ID}`,
        integrations: [
          new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: false,
          }),
        ],
        replaysSessionSampleRate: 0, // Session Replay
        replaysOnErrorSampleRate: 0, // Error replays
      });
    }
  };

  // Gestión de grabación manual
  const replay = Sentry.getReplay();

  const startManualRecord = () => {
    if (replay) {
      if (replay._replay.recordingMode === "buffer") {
        replay.stop(); // Detiene grabación en modo buffer
        replay.start(); // Inicia grabación manual
      }
    }
  };

  const stopManualRecord = () => {
    if (replay) {
      replay.stop();
    }
  };

  return {
    initSentry,
    startManualRecord,
    stopManualRecord,
  };
};
