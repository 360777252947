import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

import { useStore } from "../../../hooks/useStore";

import { Text, TextInput } from "shipnow-mercurio";
import { Grid } from "@mui/material";

const PackageWeighter = observer((props) => {
  const { handleButtonDisabled, onChange, order, showPackageType } = props;

  const [invalidFields, setInvalidFields] = useState([]);
  const [stepFocus, setStepFocus] = useState("weight");
  const [orderPckgs, setOrderPckgs] = useState([]);

  const store = useStore();

  useEffect(() => {
    let pcks = order.packages;
    let _orderPckgs = [];

    for (let i = 0; i < pcks.length; i++) {
      _orderPckgs.push({ type: pcks[i].type, weight: pcks[i].weight / 1000 });
    }

    setOrderPckgs(_orderPckgs);
    onChange && onChange(orderPckgs);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* handle button disabling  */
  useEffect(() => {
    const thereAreEmptyFields = () => {
      if (showPackageType) {
        return orderPckgs.some((p) => p.weight <= 0 || !p.type);
      } else {
        return orderPckgs.some((p) => p.weight <= 0);
      }
    };

    handleButtonDisabled(thereAreEmptyFields() || invalidFields.length > 0);
  }, [handleButtonDisabled, invalidFields, orderPckgs, showPackageType]);

  const sanitizeWeight = (value) => {
    if (
      value &&
      typeof value === "string" &&
      value.includes(",") &&
      value !== "0," &&
      value !== "0,0" &&
      value !== "0,00" //TODO: Mejorar esta validación
    ) {
      return parseFloat(value.replace(",", ".")); // Hack to change ',' and use '.'
    }

    return value;
  };

  const handleChange = (index, sender, value, name, invalidField) => {
    if (!orderPckgs) {
      return;
    }

    const splitName = name.split("-");
    const inputName = splitName[0];

    if (inputName === "weight") {
      let _value = sanitizeWeight(value);

      if (isNaN(_value)) return;

      const newOrderPckgs = orderPckgs;
      newOrderPckgs[index].weight = _value;

      setOrderPckgs(newOrderPckgs);
      onChange && onChange(newOrderPckgs);
    }

    if (inputName === "package_type") {
      const newOrderPckgs = orderPckgs;
      newOrderPckgs[index].type = value;

      setOrderPckgs(newOrderPckgs);
      onChange && onChange(newOrderPckgs);
    }

    if (invalidField.type !== "success") {
      let invalidFieldFound = invalidFields.find(
        (field) => field.name === name && field.packageIndex === index
      );

      if (!invalidFieldFound) {
        setInvalidFields((prev) => {
          const fields = prev.slice(); // clone array.
          fields.push({ name: name, packageIndex: index });
          return fields;
        });
      }
    } else {
      setInvalidFields((prev) => {
        const clone = prev.slice();
        return clone.filter((f) => f.name !== name && f.packageIndex !== index);
      });
    }
  };

  const handleWeightAccept = () => {
    setStepFocus("package");
  };

  const handleWeightKeyPress = (e) => {
    const pressedkey = e.charCode;
    const enterKey = 13;

    if (pressedkey === enterKey) {
      handleWeightAccept();
    }
  };

  const renderInputPackageEdit = (orderPackage, index) => {
    const orderPackages = store.ui.lists.packageTypes.map(
      (packageType) => packageType.key.id
    );

    return (
      <>
        <Grid
          container
          key={`po-package-${index}`}
          className={!showPackageType ? "has-text-centered" : ""}
        >
          <Grid
            item
            className={`${showPackageType ? "" : "mt-3"}`}
            xs={showPackageType ? "" : 6}
          >
            <Text className="mb-1" type="captionRegular">
              {`Usá la balanza para pesar el paquete #${index + 1}`}
            </Text>
            <TextInput
              key={`weight-${index}`}
              onChange={(...ars) => handleChange(index, ...ars)}
              placeholder="Peso del paquete ..."
              onKeyPress={handleWeightKeyPress}
              validate={(value) => {
                if (
                  /^\d*([,.]\d+)?$/.test(value) &&
                  sanitizeWeight(value) > 0
                ) {
                  return {
                    type: "success",
                    message: "",
                  };
                }

                return {
                  type: "error",
                  message: "",
                };
              }}
              name="weight"
              unit="Kg"
              required
              autofocus={stepFocus === "weight" && index === 0}
              value={orderPackage.weight}
              dontClearOnEnter
            />
          </Grid>

          {showPackageType && (
            <Grid item xs>
              <Text className="mb-1" type="captionRegular">
                {`Escanea o elegí el tamaño de caja para el paquete #${
                  index + 1
                }`}
              </Text>

              <TextInput
                key={`package-length-${index}`}
                onChange={(...ars) => handleChange(index, ...ars)}
                placeholder="Tipo de caja"
                name="package_type"
                required
                validate={(value) => {
                  if (!orderPackages.includes(parseInt(value))) {
                    return {
                      type: "error",
                      message: `Los posibles valores son ${orderPackages.join(
                        ", "
                      )}`,
                    };
                  }

                  return {
                    type: "success",
                    message: "",
                  };
                }}
                value={orderPackage.type ? orderPackage.type : null}
                type="number"
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs>
        {orderPckgs.map((orderPackage, index) =>
          renderInputPackageEdit(orderPackage, index)
        )}
      </Grid>
    </Grid>
  );
});

PackageWeighter.propTypes = {
  handleButtonDisabled: PropTypes.func,
  onChange: PropTypes.func,
  order: PropTypes.object,
  showPackageType: PropTypes.bool,
};

PackageWeighter.defaultProps = {
  handleButtonDisabled: null,
  onChange: null,
  order: null,
  showPackageType: false,
};

export default PackageWeighter;
