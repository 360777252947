import { Store } from "../lib";
import { Movement } from "../models";
import { action } from "mobx";
export default class MovementsStore extends Store {
  model = Movement;
  @action
  stats(account_id) {
    let fetchParams = Object.assign({}, { seller_id: account_id });

    return this.fetch("stats", fetchParams);
  }

  statsV2(account_id) {
    return this.appStore.coreAPIClient.sendRequest(
      `/movements/stats?seller_id=${account_id}`,
      "GET"
    );
  }

  paymentRequest(params) {
    return this.appStore.coreAPIClient.sendRequest(
      "/payment_requests",
      "POST",
      params
    );
  }
}
