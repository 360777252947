import React from "react";
import PropTypes from "prop-types";

import { Banner } from "shipnow-mercurio";

export const DownloadSampleFileBanner = (props) => {
  const { margin, onClick } = props;

  return (
    <Banner
      margin={margin}
      outlinedButton={{
        children: "Descargar archivo de ejemplo",
        onClick: onClick,
      }}
      text="Si tenés dudas, tenemos un archivo de ejemplo que podés bajar y completar."
      type="info"
    />
  );
};

DownloadSampleFileBanner.propTypes = {
  margin: PropTypes.string,
  onClick: PropTypes.func,
};

DownloadSampleFileBanner.defaultProps = {
  margin: "a-0",
  onClick: null,
};
