import React from "react";
import PropTypes from "prop-types";

import { Avatar, Box, Flex, TextV2 } from "shipnow-mercurio";

export const AccountSelectItem = (props) => {
  const { account } = props;

  return (
    <Flex justifyContent="start">
      <Box margin="r-8">
        <Avatar size="xs" image={account.image_url} />
      </Box>

      <div>
        <TextV2 color={account.active ? null : "blackMediumEmphasis"}>
          {account.company_name}
        </TextV2>

        {!account.active && (
          <TextV2
            color={account.active ? null : "blackMediumEmphasis"}
            size="captionRegular"
          >
            Inactiva
          </TextV2>
        )}
      </div>
    </Flex>
  );
};

AccountSelectItem.propTypes = {
  account: PropTypes.object,
};

AccountSelectItem.defaultProps = {
  account: {},
};
