/* eslint-disable */

/* attributes list */
export const ORDER_ADDRESS_ATTRS = [
  "city",
  "floor",
  "line",
  "state",
  "street_name",
  "street_number",
  "unit",
  "zip_code",
];

export const ORDER_BUYER_ATTRS = [
  "doc_number",
  "doc_type",
  "email",
  "last_name",
  "name",
  "phone",
];

export const ORDER_TRANSFORM_FIELDS_NAME = {
  city: "Ciudad",
  floor: "Piso",
  line: "Dirección",
  state: "Provincia",
  street_name: "Calle",
  street_number: "Número",
  unit: "Departamento",
  zip_code: "Código postal",
};

/* statuses */
export const NEW = "new";
export const RESCHEDILING_VISIT = "rescheduling_visit";
export const VISIT_SCHEDULED = "visit_scheduled";
export const VISIT_SHIPPING = "visit_shipping";
export const VISIT_DISPATCHED = "visit_dispatched";
export const DISPATCHED = "dispatched";
export const NOT_DELIVERED = "not_delivered";
export const CANCELLED = "cancelled";
export const DELIVERED = "delivered";
export const VISIT_FALIED = "visit_failed";
export const PROCESSED = "check_in_processed";
export const TO_DISPATCH = "processing";
export const TO_RETURN = "check_in_visit_scheduled"
export const RETURNED = "check_in_completed";
export const SHIPPED = "shipped";
export const CHECK_IN_SHIPPED = "check_in_shipped";
export const READY_TO_PICK = "ready_to_pick";
export const ON_HOLD = "on_hold";
export const READY_TO_SHIP = "ready_to_ship";
export const AWAITING_PAYMENT = "awaiting_payment";

export const IN_POST_OFFICE = "in_post_office";
export const ORDER_PROCESSED ="processed";
export const ORDER_RETURNED ="completed";


export const validCheckInStatuses = [ORDER_PROCESSED, TO_DISPATCH, SHIPPED, ORDER_RETURNED];

//* FILTERS NAMES.

//? return statuses
export const RETURN_A_DESPACHAR_FILTER = "return_ready_to_ship";
export const RETURN_A_RETIRAR_FILTER = "ready_to_ship_exchange_pap";
export const RETURN_DESPACHADA_FILTER = "return_shipped";
export const RETURN_DEVUELTA_FILTER = "returned";
export const RETURN_NO_DEVUELTA_FILTER = "not_returned";
export const RETURN_NO_REINGRESADA_A_STOCK_FILTER = "not_stocked";
export const RETURN_EN_SUCURSAL_FILTER = "shipped_exchange_sap_and_warehouse"
export const RETURN_NO_RECIBIDA_FILTER = "not_delivered_exchange_sap_and_warehouse";
export const RETURN_NO_RETIRADA_FILTER = "not_delivered_exchange_pap";
export const RETURN_PROCESADA_FILTER = "received";
export const RETURN_RECIBIDA_FILTER = "delivered_exchange_sap_and_warehouse";
export const RETURN_REINGRESADA_A_STOCK_FILTER = "stocked";
export const RETURN_RETIRADA_FILTER = "delivered_exchange_pap";
