import { useField } from "formik";
import React from "react";
import { Checkbox } from "shipnow-mercurio";

export const CheckBoxFormik = (props) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const getValue = (checked, value, name) => {
    helpers.setTouched(true);
    if (!!props.value) {
      // Si se provee un value se va a retornar el estado del check y el valor
      return { check: checked, value: value, name: name };
    } else {
      // Si no se provee un value por prop se retornará un boolean
      return checked;
    }
  };

  return (
    <Checkbox
      {...props}
      {...field}
      onClick={(a, b, c) => helpers.setValue(getValue(a, b, c))}
      value={!!props.value ? props.value : ""}
      isChecked={!!props.value ? field.value.check : field.value}
    />
  );
};
