import { Store } from "../lib";
import { Account } from "../models";

export default class AccountsStore extends Store {
  model = Account;
  baseWebURI = "/app/accounts";

  getFiscalInformation(id, docNumber) {
    return this.appStore.coreAPIClient.sendRequest(
      `/accounts/${id}/fiscal_data?doc_number=${docNumber}`,
      "GET"
    );
  }

  invalidEmail(_email) {
    return this.appStore.coreAPIClient.sendRequest(
      `/accounts/available_email?email=${_email}`,
      "GET",
      null,
      false
    );
  }

  getAccount(id, filters) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient
      .sendRequest(`/accounts/${id}?${searchString}`, "GET")
      .then((res) => {
        return new Account(res.results, this);
      });
  }

  put(id, params) {
    return this.appStore.coreAPIClient
      .sendRequest(`/accounts/${id}`, "PUT", params)
      .then((res) => new Account(res.results, this));
  }
}
