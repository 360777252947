/* Esta clase solo se usa en el app store para la store de geocoder y uiStore. En realidad no tiene utilidad 
   porque no hace uso del localStorage pero si lo elimino deja de funcionar poruqe en algún momento trata de 
   hacer un get y no tiene un adapter para hacer un get.

   TODO: Revisar como podemos solucionarlo.
*/
export default class LocalStorageStoreClient {
  namespace = "";

  constructor(namespace) {
    this.namespace = namespace;
  }

  errorMensaje() {
    console.warn(
      "SHIPNOW_DEV: Para una mejor experiencia habilitar permisos de cookies."
    );
  }

  get(path, id = null) {
    let stored;

    try {
      stored = localStorage.getItem(
        `${this.namespace}_${path}_${id ? id : "default"}`
      );

      if (stored) {
        return Promise.resolve(JSON.parse(stored));
      } else {
        return Promise.reject(new Error("Not found"));
      }
    } catch {
      this.errorMensaje();
      return Promise.reject(new Error("Not found"));
    }
  }

  post(path, item) {
    let toStore = JSON.stringify(item.toJS());
    const id = item && item.id;

    try {
      localStorage.setItem(
        `${this.namespace}_${path}_${id ? id : "default"}`,
        toStore
      );

      return Promise.resolve(item);
    } catch {
      this.errorMensaje();
      return Promise.reject(new Error("Not found"));
    }
  }

  put(path, item) {
    return this.post(path, item);
  }

  delete(path, id) {
    try {
      localStorage.removeItem(`${this.namespace}_${path}_${id || "default"}`);

      return id;
    } catch {
      this.errorMensaje();
    }
  }
}
