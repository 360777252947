import { Model } from "../lib";
import { action, observable, computed } from "mobx";

export default class GoodsInItem extends Model {
  urlRoot = "/goods_in_items";
  @observable goodsInOrder = null;
  @observable goodsInProduct = null;
  @observable goodsIn;

  constructor(attributes, store) {
    let defaultAttributes = {
      quantity_received: null,
      quantity_sent: null,
      quantity_unlabel: null,
      type: null,
      variant: null,
      product: null,
      resource_type: null,
      resource: null,
      goods_in_id: null,
    };

    super(attributes, store, defaultAttributes);
  }

  @action
  afterSetData() {
    if (this.resource && this._store) {
      if (this.resource_type === "order") {
        this.goodsInOrder = this._store.appStore.orders.getFromData(
          this.resource
        );
        this.goodsInOrder._status = "ok";
      } else {
        this.goodsInProduct = this._store.appStore.variants.getFromData(
          this.resource
        );
        this.goodsInProduct._status = "ok";
      }
    }

    if ((this.transfer || this.pickup) && this._store) {
      this.goodsIn = this._store.appStore.goodsIns.getFromData(
        this.transfer || this.pickup
      );
    }
  }

  @computed
  get isSelected() {
    return (
      !!this.quantity_unlabel &&
      (this.quantity_unlabel === this.quantity_received ||
        this.quantity_unlabel === this.quantity_sent)
    );
  }
  @computed
  get isSelectedInGoodsInTable() {
    return this._store.appStore.ui.lists.selectedOrders.find(
      (order) => order.id === this.goodsInOrder.id
    );
  }
  isGoodsInItem() {
    return true;
  }
}
