import moment from "moment";

export const ACCOUNT_TABS = ["brand", "notification", "operation"];

export const ONBOARDING_INDUSTRIES_LIST = [
  {
    key: "Indumentaria deportiva y deportes en general",
    value: "Indumentaria deportiva y deportes en general",
  },
  {
    key: "Indumentaria, textiles, moda y accesorios",
    value: "Indumentaria, textiles, moda y accesorios",
  },
  {
    key: "Cosmética y perfumería",
    value: "Cosmética y perfumería",
  },
  {
    key: "Electrónica",
    value: "Electrónica",
  },
  {
    key: "Deco-hogar-bazar",
    value: "Deco-hogar-bazar",
  },
  {
    key: "Electrodomésticos",
    value: "Electrodomésticos",
  },
  {
    key: "Alimentos, bebidas y limpieza",
    value: "Alimentos, bebidas y limpieza",
  },
  {
    key: "Infantiles (juguetes e indumentaria)",
    value: "Infantiles (juguetes e indumentaria)",
  },
  {
    key: "Herramientas y construcción",
    value: "Herramientas y construcción",
  },
  {
    key: "Accesorios para autos",
    value: "Accesorios para autos",
  },
  {
    key: "Librería, oficina y educación",
    value: "Librería, oficina y educación",
  },
  {
    id: "onbaording--aboutBuisness-industriesSelect-other",
    key: "Otro",
    value: "Otro",
  },
];

export const ONBOARDING_SALES_LIST = [
  { key: "Estudionube + TOM", value: "Estudionube + TOM" },
  { key: "Inbound + ventas", value: "Inbound + ventas" },
  { key: "Inbound Automático", value: "Inbound Automático" },
  { key: "SO + Ventas", value: "SO + Ventas" },
  { key: "Ventas - outbound", value: "Ventas - outbound" },
  { key: "Ventas - Referido", value: "Ventas - Referido" },
  { key: "#N/A", value: "#N/A" },
];

export const ONBOARDING_MONTHLY_SHIPMENT_LIST = [
  {
    key: "0-50",
    value: "0-50",
  },
  {
    key: "50-150",
    value: "50-150",
  },
  {
    key: "150-300",
    value: "150-300",
  },
  {
    key: "300-450",
    value: "300-450",
  },
  {
    key: "450-600",
    value: "450-600",
  },
  {
    key: "600-1000",
    value: "600-1000",
  },
  {
    key: "Más de 1000",
    value: "1000+",
  },
  {
    key: "Aún no realizo envíos",
    value: "still_not_shipping",
  },
];

export const ACCOUNT_LOCATION_LIST = [
  {
    key: "Ciudad Autónoma de Buenos Aires (CABA)",
    value: "Caba",
  },
  {
    key: "Zona norte de Buenos Aires",
    value: "Caba norte",
  },
  {
    key: "Zona sur de Buenos Aires",
    value: "Caba sur",
  },
  {
    key: "Zona oeste de Buenos Aires",
    value: "Caba oeste",
  },
  {
    key: "Zárate",
    value: "Zarate",
  },
  {
    key: "Campana",
    value: "Campana",
  },
  {
    key: "Lobos",
    value: "Lobos",
  },
  {
    key: "Cañuelas",
    value: "Cañuelas",
  },
  {
    key: "Córdoba capital",
    value: "Cordoba capital",
  },
  {
    key: "Rosario",
    value: "Rosario",
  },
  {
    key: "Otro",
    value: "Otro",
  },
];

export const WORDING_BANNER_TARIFF_RISE =
  "El 01/07, actualizaremos los valores de nuestras tarifas.";

//fecha hasta la que se deberias mostrar el banner
const targetDate = moment("2023-07-04", "YYYY-MM-DD");
const currentDate = moment();

export const SHOW_BANNER_TARIFF_RISE = currentDate.isBefore(targetDate);

//tipos de políticas de seguridad
export const PER_ITEM = "per_item";
export const PER_ORDER = "per_order";
export const PER_SALE_PRICE = "per_sale_price";
export const INACTIVE = "inactive";
