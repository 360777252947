import { Model } from "../lib";

export default class UISettings extends Model {
  constructor(attributes, store) {
    let defaultAttributes = {
      exchanges: {
        showArchived: false,
        showExternal: false,
      },
      goodsIns: {
        showArchived: false,
      },
      kits: {
        showArchived: false,
      },
      layout: {
        expandedSidebar: false,
      },
      orders: {
        showArchived: false,
        showExternal: false,
      },
      products: {
        showArchived: false,
      },
      returns: {
        showArchived: false,
        showExternal: false,
      },
      variants: {
        showArchived: false,
      },
      warehouses: {
        showArchived: false,
      },
    };

    // the only exeption because the standar breacks the sidebar
    let attrs = Object.assign(defaultAttributes, attributes);

    super(attrs, store);
  }
}
