import { Store } from "../lib";
import { Order } from "../models";

export default class OrdersStore extends Store {
  model = Order;
  baseWebURI = "/app/orders";

  saveChangeOrder(changeOrder, returnOrder, shippingType) {
    const params = {
      send: changeOrder.toParams(),
      exchange: returnOrder.toParams(),
      type: shippingType,
      seller_id: changeOrder.seller_id,
    };
    return this.adapter.post("/orders/changes", params);
  }

  sendRemito(order, blobFile) {
    const url = `/orders/${order.id}/dispatch_document`;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blobFile);
      reader.onloadend = () => {
        let base64File = reader.result;
        base64File = base64File.replace("data:application/pdf;base64,", "");

        const params = {
          file: { data: base64File },
          copies: 3,
        };

        this.adapter
          .post(url, params)
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          )
          .catch((error) => {
            reject(error);
          });
      };
    });
  }

  fetchAssociatedOrders(filters) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient
      .search(`/orders?${searchString}`)
      .then((orders) =>
        orders.results.map((order) => {
          const o = new Order(order, this);
          o._status = "ok";
          return o;
        })
      );
  }

  labels(id, type) {
    return this.appStore.coreAPIClient.sendRequest(
      `/orders/${id}/labels`,
      "POST",
      { type }
    );
  }

  fetchOrderByReference(reference, id) {
    return this.appStore.coreAPIClient.search(
      `/orders?external_reference=${reference}&seller_id=${id}`
    );
  }

  getOrder(id, filters = {}) {
    const searchString = new URLSearchParams(filters);

    return this.appStore.coreAPIClient
      .sendRequest(`/orders/${id}?${searchString}`, "GET")
      .then((res) => {
        const order = new Order(res.results, this);
        order._status = "ok";
        return order;
      });
  }

  put(id, params) {
    return this.appStore.coreAPIClient
      .sendRequest(`/orders/${id}`, "PUT", params)
      .then((res) => new Order(res.results, this));
  }

  updateStatus(status, id, model) {
    model.beginUpdate();
    this.appStore.coreAPIClient
      .put(
        "/orders",
        {
          status,
        },
        id
      )
      .then(
        (res) => {
          if (res && res["results"]) {
            model.set(res["results"]);
            model.endUpdate();
          }
        },
        (err) => {
          model.endUpdate(err);
        }
      );
    return model;
  }

  scan(params) {
    const url = `/cross_docking/scan`;

    return this.appStore.coreAPIClient.post(url, params).then((res) => {
      res.results.forEach((el) => {
        el.order = new Order(el.order, this);
        el.order._status = "ok";
      });

      return res.results;
    });
  }
}
