import React from "react";

import { FAQLink } from "./FAQLink";

import { MELI_HOW_ACTIVE_FLEX } from "../../../assets/constants/links";

export const ActiveMeliFAQ = () => {
  return (
    <FAQLink
      button={{
        id: "stores--flexDisabled-btn-howActiveFlex",
        href: MELI_HOW_ACTIVE_FLEX,
        margin: "l-3",
      }}
      text="cómo activar Flex en Mercado Libre."
    />
  );
};
