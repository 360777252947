import React from "react";
import { Button, Flex } from "shipnow-mercurio";
import PropTypes from "prop-types";

const ModalFooter = ({
  cancelButtonText,
  confirmButtonText,
  onClose,
  onConfirm,
  disabled,
}) => {
  return (
    <Flex justifyContent="right">
      <Button type="text" margin="r-8" onClick={onClose}>
        {cancelButtonText}
      </Button>
      <Button isdefault type="text" onClick={onConfirm} disabled={disabled}>
        {confirmButtonText}
      </Button>
    </Flex>
  );
};

ModalFooter.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  disabled: PropTypes.bool,
};

ModalFooter.defaultProps = {
  onClose: null,
  onConfirm: null,
  cancelButtonText: "No, cancelar",
  confirmButtonText: "Sí, continuar",
  disabled: false,
};
export default ModalFooter;
