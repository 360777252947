import React, { useState, useCallback } from "react";

import { useToasts } from "react-toast-notifications";

import { MessageLayout } from "../components/Common";

import { Button, Loader, TextV2 } from "shipnow-mercurio";

export const useResponseToast = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { addToast, removeToast } = useToasts();

  const errorToast = useCallback(
    (error, options) => {
      const message =
        error || "Se produjo un error, por favor inténtalo mas tarde.";

      setError(true);
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
        ...options,
      });
    },
    [addToast]
  );

  const successToast = useCallback(
    (message) => {
      addToast(message, {
        appearance: "success",
        autoDismiss: true,
      });
    },
    [addToast]
  );

  const infoToast = useCallback(
    (message) => {
      addToast(message, {
        appearance: "info",
        autoDismiss: true,
      });
    },
    [addToast]
  );

  const toastWithButton = useCallback(
    (title, buttonText, onClick) => {
      let toast;

      const handleClick = () => {
        onClick && onClick();

        toast && removeToast(toast);
      };

      const content = (
        <>
          <TextV2 className="mb-2">{title}</TextV2>
          <Button onClick={handleClick} type="outlined" color="highlight">
            {buttonText}
          </Button>
        </>
      );

      toast = addToast(content, { appearance: "info", autoDismiss: true });
    },
    [addToast, removeToast]
  );

  const RenderLoader = ({ isFullscreen = true }) => {
    return (
      <Loader
        animation="spin"
        className={isFullscreen && "fullscreen"}
        label="Cargando ..."
      />
    );
  };

  const RenderEmptyContent = () => {
    return (
      <MessageLayout
        image="no-content"
        message="Se produjo un error, por favor inténtalo mas tarde."
      />
    );
  };

  return {
    errorToast,
    error,
    setError,
    successToast,
    infoToast,
    loading,
    setLoading,
    RenderLoader,
    RenderEmptyContent,
    toastWithButton,
  };
};
