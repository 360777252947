import React from "react";
import PropTypes from "prop-types";

import { Button, Flex, IconContainer, TextV2 } from "shipnow-mercurio";

export const FAQLink = (props) => {
  const { button, margin, text } = props;

  return (
    <Flex
      alignItems="start"
      justifyContent="flex-start"
      margin={margin}
      style={{ width: "auto" }}
    >
      <IconContainer color="highlight" icon="helpCenter" margin="r-4" />

      <TextV2 margin="t-2">
        Conoce más sobre{" "}
        <Button {...button} type="link">
          {text}
        </Button>
      </TextV2>
    </Flex>
  );
};

FAQLink.propTypes = {
  button: PropTypes.shape({
    id: PropTypes.string,
    href: PropTypes.string,
    margin: PropTypes.string,
  }),
  margin: PropTypes.string,
  text: PropTypes.string,
};

FAQLink.defaultProps = {
  button: PropTypes.shape({
    id: null,
    href: null,
    margin: "a-0",
  }),
  margin: "a-0",
  text: "",
};
