import React from "react";
import PropTypes from "prop-types";

import { Marker as LeafletMarker, Tooltip } from "react-leaflet";
import L from "leaflet";

import marker from "../../assets/images/marker.svg";
import home from "../../assets/images/house-window-solid1.svg";

const Marker = (props) => {
  const { onClick, position, tooltip } = props;

  const handleMarkerClick = () => {
    onClick && onClick(position);
  };

  const customIcon = new L.Icon({
    iconUrl: props.homeMarker ? home : marker,
    //el icon size y el iconAnchor estan en proporcion para compensar y hacer que el icono quede siempre fijo
    iconSize: props.homeMarker ? [45, 45] : [60, 60], // Aumenta el tamaño del icono un 25% (original: 48x48, nuevo: 60x60)
    iconAnchor: props.homeMarker ? [23, 45] : [30, 60],
    tooltipAnchor: [0, -60], // Ajusta el anclaje del icono si es necesario
  });

  return (
    <LeafletMarker
      draggable={props.draggable}
      icon={customIcon}
      onClick={handleMarkerClick}
      onDragEnd={props.onDrag}
      position={position}
    >
      {tooltip && <Tooltip direction="top"> {tooltip} </Tooltip>}
    </LeafletMarker>
  );
};

Marker.propTypes = {
  draggable: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  onDrag: PropTypes.func,
  position: PropTypes.array,
  tooltip: PropTypes.any,
};

Marker.defaultProps = {
  draggable: false,
  icon: "location-dot",
  onClick: null,
  onDrag: null,
  position: null,
  tooltip: "",
};

export default Marker;
