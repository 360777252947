import { getCore, putCore } from "../../../api/services/axiosCore.service";

/**
 * fetches a single user by ID and maps the result to an `User` instance.
 * @param {string} id - the ID of the user to retrieve.
 * @param {Object} [params] - optional parameters to include in the request.
 * @returns {Promise<Object>} A promise that contains the user data wrapped along with additional metadata.
 */
export const getUser = (id, params) => {
  return getCore(`/users/${id}`, params);
};

/**
 * Updates a user.
 * @param {string} id - The unique identifier of the user to be updated.
 * @param {Object} params - An object containing the updated user parameters.
 * @returns {Promise} A promise that resolves to the response of the update request.
 */
export const updateUser = (id, params) => {
  return putCore(`/users/${id}`, params);
};
