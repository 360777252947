import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

import { useStore } from "../../../../hooks/useStore";

import Countdown from "./components/Countdown";

import {
  DOMAIN_LOCALHOLST,
  DOMAIN_SHIPNOW,
  IS_DEVELOPMENT,
} from "../../../../assets/constants/environment";

import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  TextV2,
  Flex,
  Button,
} from "shipnow-mercurio";

const EmailConfirmationModal = observer((props) => {
  const { onClose, onConfirm } = props;

  const cookieOptions = {
    "Max-Age": 86400,
    domain: IS_DEVELOPMENT ? DOMAIN_LOCALHOLST : DOMAIN_SHIPNOW,
    path: "/",
  };

  const TIME = 6 * 60; //6 seconds.

  const refreshTimer = useRef(null);
  const store = useStore();

  const { cookieEmailModal } = store;

  const [validEmail, setValidEmail] = useState(false);
  const [countEmailResend, setCountEmailResend] = useState(() => {
    const sent_at = store.loggedInUser.confirmation_sent_at;

    if (sent_at) {
      const now = new Date();
      const deadline = new Date(sent_at);
      const lapseTime = (now.getTime() - deadline.getTime()) / 1000;

      if (lapseTime < TIME) {
        // time not exceeded.
        return deadline.setSeconds(deadline.getSeconds() + TIME);
      }
    }

    return null;
  });

  const clearTimer = () => {
    if (refreshTimer.current) clearTimeout(refreshTimer.current);
  };

  const checkingEmail = useCallback(() => {
    clearTimer();

    store.users
      .validateConfirmationMail()
      .then((res) => {
        setValidEmail(!!res.results.confirmed_at);
        store.loggedInUser.confirmed_at = res.results.confirmed_at;
      })
      .catch((err) => console.error(err));

    if (!validEmail) {
      const id = setTimeout(checkingEmail, store.settings.refreshRate * 1000);
      refreshTimer.current = id;
    }
  }, [
    store.loggedInUser.confirmed_at,
    store.settings.refreshRate,
    store.users,
    validEmail,
  ]);

  /* pooling trigger to validate email */
  useEffect(() => {
    if (store.loggedInUser.confirmed_at) {
      clearTimer();
    } else {
      checkingEmail();
    }
  }, [store.loggedInUser.confirmed_at]); // eslint-disable-line react-hooks/exhaustive-deps

  /* clear timeout when unmounting component */
  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  const handleClickResendEmail = () => {
    // if the email is valid => no resend email.
    if (!validEmail) {
      const now = new Date();
      store.users.resendConfirmationEmail();
      setCountEmailResend(now.setSeconds(now.getSeconds() + TIME));
    }
  };

  const handleClick = () => {
    cookieEmailModal.set(true, false, cookieOptions);

    onConfirm && onConfirm();
    onClose && onClose();
  };

  const closeButton = () => {
    cookieEmailModal.set(true, false, cookieOptions);
    onClose();
  };

  return (
    <Modal show={props.show && !validEmail} onClose={closeButton} width="650px">
      <ModalHeader radius={4}></ModalHeader>
      <ModalContent className="pb-0 pt-0">
        <TextV2 margin="b-24 t-16" size="title">
          {props.titleText}
        </TextV2>

        <TextV2 margin="b-32">{props.descriptionText}</TextV2>

        <Flex justifyContent="normal" margin="b-20">
          <TextV2 margin="r-8" size="bodySemibold">
            ¿Aún no lo has recibido?
          </TextV2>

          {countEmailResend ? (
            <TextV2>
              Reenviar email en{" "}
              <TextV2 as="span" size="bodySemibold">
                <Countdown
                  deadline={countEmailResend}
                  noTime={() => setCountEmailResend(null)}
                />
              </TextV2>
            </TextV2>
          ) : (
            <Button type="text" onClick={handleClickResendEmail}>
              Reenviar email
            </Button>
          )}
        </Flex>
      </ModalContent>

      <ModalFooter radius={4} className="py-0 pr-0">
        <div style={{ paddingRight: "20px", paddingBottom: "20px" }}>
          <Button type="text" onClick={handleClick}>
            Entendido
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
});

EmailConfirmationModal.propTypes = {
  descriptionText: PropTypes.node,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  titleText: PropTypes.string,
};

EmailConfirmationModal.defaultProps = {
  descriptionText:
    "Verifica tu cuenta desde el email que te enviamos. No olvides revisar “Correo no deseado” y “Spam”.",
  onClose: null,
  onConfirm: null,
  show: false,
  titleText: "Confirma tu email para avanzar",
};

export default EmailConfirmationModal;
