import axios from "axios";

import { transformRequestRes } from "../utils/requests.utils";

import { getShipnowHeader } from "../../modules/common/utils/envoirenment.utils";
import { getPaginateData } from "../../modules/common/utils/table.utils";
import { getCookieValue } from "../../modules/common/utils/cookie.utils";

export const axiosCoreClient = axios.create({
  baseURL: process.env.REACT_APP_SHIPNOW_CORE_URL,
  headers: {
    Accept: "application/json",
    "cache-control": "no-cache",
    "Content-Type": "application/json",
    pragma: "no-cache",
    "X-Shipnow-App": getShipnowHeader(),
  },
});

/**
 * sends a GET request to the specified URL with the given parameters and returns the results.
 * @param {string} url - the endpoint URL to send the request to.
 * @param {Object} [params] - an optional object containing query parameters to include in the request.
 * @returns {Promise<Object>} a promise that contains the response data, including either a list of results or a single object, along with headers and status.
 */
export const getCore = (url, params) => {
  const token = getCookieValue("sn_loggin_user_token");

  const searchString = new URLSearchParams(params);

  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  url = `${url}?${searchString}`;

  //? When receiving a list, the information is found in data.results, and when receiving
  //? an object, the response is in data.
  return axiosCoreClient
    .get(url, options)
    .then((res) => transformRequestRes(res));
};

/**
 * sends a GET request to the specified URL and returns the results along with pagination data.
 * @param {string} url - the endpoint URL to send the request to.
 * @param {Object} [options] - an optional object containing query parameters and other options for the request.
 * @returns {Promise<Object>} a promise that contains the response data, including a paginated list of results, headers, and status.
 */
export const getListCore = (url, options) => {
  return getCore(url, options).then((res) => {
    const paginate = getPaginateData(res.headers);

    return {
      paginate,
      ...res,
    };
  });
};

/**
 * sends a POST request to the specified URL with the provided data and returns the results.
 * @param {string} url - the endpoint URL to send the request to.
 * @param {Object} data - the data to be sent in the request body.
 * @returns {Promise<Object>} a promise that contains the response data, including either a list of results or a single object, along with headers and status.
 */
export const postCore = (url, data) => {
  const token = getCookieValue("sn_loggin_user_token");

  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axiosCoreClient
    .post(url, data, options)
    .then((res) => transformRequestRes(res));
};

/**
 * sends a PUT request to the specified URL with the provided data and returns the results.
 * @param {string} url - the endpoint URL to send the request to.
 * @param {Object} data - the data to be sent in the request body.
 * @returns {Promise<Object>} a promise that contains the response data, including either a list of results or a single object, along with headers and status.
 */
export const putCore = (url, data) => {
  const token = getCookieValue("sn_loggin_user_token");

  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axiosCoreClient
    .put(url, data, options)
    .then((res) => transformRequestRes(res));
};
