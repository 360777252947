import { Store } from "../lib";
import { GoodsInOption } from "../models";

export default class GoodsInOptionsStore extends Store {
  model = GoodsInOption;

  getOptions(filters) {
    return this.fetch("", filters, {}, false, null, true);
  }
}
