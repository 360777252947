import React from "react";
import PropTypes from "prop-types";

import { Text, Flex, Button } from "shipnow-mercurio";

import moment from "moment-timezone";

import { PRIVACY_POLICIES } from "../../../assets/constants/links";

const Footer = (props) => {
  const { isMobile, ...others } = props;

  return (
    <Flex className="mt-2 mb-1" flexDirection={isMobile ? "column" : "row"}>
      <Text {...others} type="captionRegular">
        © 2016-{moment().format("YYYY")} SHIP NOW S.R.L., Todos Los Derechos
        Reservados.
      </Text>
      <Button type="link" href={PRIVACY_POLICIES} textsize="captionRegular">
        Políticas de Privacidad
      </Button>
    </Flex>
  );
};

Footer.propTypes = {
  isMobile: PropTypes.bool,
};

Footer.defaultProps = {
  isMobile: false,
};

export default Footer;
