import { Store, Model } from "../lib";

export default class GeocoderStore extends Store {
  model = Model;
  urlRoot = "geo";

  getCoordinates(address) {
    const path = "/addresses/geocode";

    const data = {
      ...address,
      country: address.country.code,
    };

    const params = {
      fields: "zip_code_data",
    };

    return this.appStore.geoCoderAPIClient.post(path, data, true, params);
  }

  parse(address) {
    return this.appStore.geoCoderAPIClient.post("/address", {
      ...address,
      country: address.country.code,
    });
  }

  isValidAddress(address) {
    return (
      address.street_name &&
      address.street_number &&
      address.zip_code &&
      address.city &&
      address.state &&
      address.country
    );
  }
}
