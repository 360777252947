import React from "react";

import { Banner } from "shipnow-mercurio";

export const BonifyAlertBanner = () => {
  return (
    <Banner
      margin="b-16"
      text="Esta acción no se puede deshacer e implica un descuento en la próxima factura del cliente. Por favor, verificá que realmente el mismo debe ser bonificado."
      type="info"
    />
  );
};
