import { Store } from "../lib";
import { CheckIn } from "../models";

export default class CheckInsStore extends Store {
  model = CheckIn;
  baseWebURI = "/check_ins";

  printResume(code) {
    return this.appStore.coreAPIClient.sendRequest(
      `${this.baseWebURI}/summary_label?destination_code=${code}`
    );
  }

  put(id, params) {
    return this.appStore.coreAPIClient
      .sendRequest(`${this.baseWebURI}/${id}`, "PUT", params)
      .then((res) => new CheckIn(res.results, this));
  }
}
