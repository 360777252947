import { Model } from "../lib";

export default class Contact extends Model {
  constructor(attributes, store) {
    let defaultAttributes = {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      company_name: null,
      locations: null,
      monthly_orders: null,
      open_question: null,
      campaign: null,
      medium: null,
    };

    super(attributes, store, defaultAttributes);
  }

  urlRoot = "/contacts";

  save() {
    const formData = new URLSearchParams();

    formData.append("first_name", this.first_name);
    formData.append("last_name", this.last_name);
    formData.append("email", this.email);
    formData.append("phone", this.phone);
    formData.append("company", this.company_name);
    this.locations.forEach((location) => {
      formData.append("00N5w00000RBIPt", location);
    });
    formData.append("00N5w00000RAddL", this.monthly_orders);
    formData.append("00N5w00000S9CMd", this.open_question);
    formData.append("00N5w00000S9E6I", this.medium); //
    formData.append("00N5w00000S9E18", this.campaign);

    formData.append("00N5w00000RAnqV", "Web");
    formData.append("oid", "00D5w000003HVJQ");
    formData.append("retURL", "https://www.shipnow.com.ar/");

    return fetch(
      "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
      {
        method: "POST",
        mode: "no-cors",
        body: formData,
      }
    );
  }
}
