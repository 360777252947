import { useField } from "formik";
import React from "react";
import { RadioGroup } from "shipnow-mercurio";

export const RadioGroupFormik = (props) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  return (
    <RadioGroup
      {...props}
      {...field}
      onClick={(sender, value) => {
        helpers.setValue(value);
      }}
    >
      {props.children}
    </RadioGroup>
  );
};
