import { Store } from "../lib";
import { Stock } from "../models";

export default class StocksStore extends Store {
  model = Stock;

  searchStock(params) {
    let fetchParams = Object.assign({}, params);

    return this.fetch("", fetchParams);
  }
}
