import { Store } from "../lib";
import { Product } from "../models";

export default class ProductsStore extends Store {
  model = Product;
  baseWebURI = "/app/inventory/products";

  searchStock(id, params) {
    let fetchParams = Object.assign({}, params);

    return this.fetch(`${id}/stocks`, fetchParams);
  }

  searchImages(id, params) {
    let fetchParams = Object.assign({}, params);

    return this.fetch(`${id}/images`, fetchParams);
  }

  update(id, params) {
    return this.appStore.coreAPIClient.sendRequest(
      `/products/${id}`,
      "PUT",
      params
    );
  }

  getImages(id) {
    return this.appStore.coreAPIClient.sendRequest(
      `/products/${id}/images`,
      "GET"
    );
  }
}
