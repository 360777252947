const styles = () => ({
  imgStyles: {
    maxHeight: "320px",
    height: "70%",
    width: "70%",
  },
  textBox: {
    margin: "auto",
    maxWidth: "420px",
  },
  wrapper: {
    margin: "auto",
    marginTop: (props) => props.mt,
    maxWidth: "560px",
    textAlign: "center",
  },
});

export default styles;
