import { Store } from "../lib";
import { GoodsIn } from "../models";

export default class GoodsInsStore extends Store {
  model = GoodsIn;
  baseWebURI = "/app/transfers";

  updateAsync(params, filters) {
    const filtersString = new URLSearchParams(filters).toString();

    return this.appStore.coreAPIClient.sendRequest(
      `/goods_ins/async?${filtersString}`,
      "PUT",
      params
    );
  }

  labels(id, filters, type) {
    const filtersString = new URLSearchParams(filters).toString();

    return this.appStore.coreAPIClient.sendRequest(
      `/goods_ins/${id}/labels?${filtersString}`,
      "POST",
      { type }
    );
  }
}
