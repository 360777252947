import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import {
  Button,
  Dropzone,
  Flex,
  Loader,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  TextV2,
} from "shipnow-mercurio";

const DropzoneModal = observer((props) => {
  const { onClose, onDrop, showLoader } = props;

  const handleImageAdd = (images) => {
    onDrop && onDrop(images);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal show onClose={handleClose}>
      <ModalHeader>
        <TextV2 size="title">Cambiar Imagen</TextV2>
      </ModalHeader>
      <ModalContent>
        {showLoader ? (
          <Loader label="Subiendo" />
        ) : (
          <Dropzone onFileAdd={handleImageAdd} />
        )}
      </ModalContent>
      <ModalFooter>
        <Flex justifyContent="right">
          <Button type="outlined" onClick={handleClose}>
            Cerrar
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
});

DropzoneModal.propTypes = {
  onClose: PropTypes.func,
  onDrop: PropTypes.func,
  showLoader: PropTypes.bool,
};

DropzoneModal.defaultProps = {
  onClose: null,
  onDrop: null,
  showLoader: false,
};

export default DropzoneModal;
