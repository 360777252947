import { Model } from "../lib";

export default class Charge extends Model {
  constructor(attributes, store) {
    let defaultAttributes = {
      seller_id: null,
      code: null,
      price: null,
      tax_price: null,
      reference: null,
      description: null,
      resource_id: null,
      discounted: null,
      read_only: null,
    };

    super(attributes, store, defaultAttributes);
  }
  urlRoot = "/charges";

  isInvoice() {
    return false;
  }
}
