import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Map as LeafletMap, TileLayer } from "react-leaflet";
import Marker from "./Marker";

import { DEFAULT_BA_COORDINATES } from "../../assets/constants/warehouses";

const Map = observer((props) => {
  const getPosition = () => {
    if (props.position) {
      return props.position;
    } else if (props.markers) {
      const lats = props.markers.map((marker) => marker.position[0]);
      const lons = props.markers.map((marker) => marker.position[1]);

      const maxLat = Math.max(...lats);
      const minLat = Math.min(...lats);
      const maxLon = Math.max(...lons);
      const minLon = Math.min(...lons);

      return [(maxLat + minLat) / 2, (maxLon + minLon) / 2];
    } else {
      return DEFAULT_BA_COORDINATES;
    }
  };

  const handleDragEnd = (event) => {
    const center = event.target.getCenter();
    props.onDragEnd && props.onDragEnd(center);
  };

  const handleMarkerClick = (marker) => {
    props.onMarkerClick && props.onMarkerClick(marker);
  };

  const getTileProps = () => {
    return Map.leafletTiles[props.tileLayer];
  };

  const getMarkers = () => {
    if (props.markers && props.markers.length > 0) {
      return props.markers;
    }

    return [
      {
        position: getPosition(),
      },
    ];
  };

  const buyerMarker = props?.buyerMarker;

  const markers = getMarkers();
  const selectedMarker = props.selectedMarker;

  return (
    <div
      style={{ width: props.width, height: props.height }}
      className={props.className}
    >
      <LeafletMap
        zoomControl={props.zoomControl}
        center={getPosition()}
        onDragend={handleDragEnd}
        zoom={props.zoom}
        style={{
          width: "100%",
          height: "100%",
          boxsadow: "rgba(3, 3, 3, 0.1) 3px 3px 6px",
        }}
      >
        <TileLayer {...getTileProps()} />

        {markers.map((marker, idx) => (
          <Marker
            key={idx}
            draggable={props.draggable}
            onDrag={props.onDrag}
            isSelected={
              selectedMarker &&
              selectedMarker.position[0] === marker.position[0] &&
              selectedMarker.position[1] === marker.position[1]
            }
            onClick={handleMarkerClick}
            {...marker}
          />
        ))}
        {buyerMarker?.position[0] && buyerMarker?.position[1] && (
          <Marker key={"buyerMarker"} homeMarker {...buyerMarker} />
        )}
      </LeafletMap>
    </div>
  );
});

Map.leafletTiles = {
  carto_positron: {
    url:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
  },
  gmap: {
    url: "https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    attribution:
      'Map data © <a href="https://www.google.com/maps/">Google Maps</a>',
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  },
};

Map.propTypes = {
  draggable: PropTypes.bool,
  height: PropTypes.string,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onMarkerClick: PropTypes.func,
  position: PropTypes.array,
  selectedMarker: PropTypes.any,
  tileLayer: PropTypes.string,
  width: PropTypes.string,
  zoom: PropTypes.number,
  zoomControl: PropTypes.bool,
};

Map.defaultProps = {
  draggable: false,
  height: "600px",
  onDrag: null,
  onDragEnd: null,
  onMarkerClick: null,
  position: null,
  selectedMarker: null,
  tileLayer: "gmap",
  width: "600px",
  zoom: 13,
  zoomControl: true,
};

export default Map;
