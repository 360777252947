//? ESLint is disabled to ensure variables are placed on a single line.
/* eslint-disable */

//? react-query keys.
export const SESSION_QUERY_KEYS = {
  all: ["session"],
  loggedUser: () => [...SESSION_QUERY_KEYS.all, "loggedUser"],
  checkRecoveryPasswordToken: (token) => [...SESSION_QUERY_KEYS.all, "check_recovery_password_token", token],
};

//? messages
export const SIGN_IN_PARAMS_ERROR = "El email o la contraseña son incorrectos. Revísalos e intenta nuevamente."
export const SIGN_IN_RATE_LIMIT_ERROR = "Alcanzaste el máximo de intentos. Revisa tu email o contraseña, y reinténtalo en 1 h.";

//? settings
export const PASSWORD_CARACTER_LENGTH = 8;
