import React, { useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import {
  Button,
  Dropzone,
  ImageItemList,
  ImageItem,
  ModalV2,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "shipnow-mercurio";
import { ImageEmptyState } from "../../Products/Show/ImageEmptyState";
import { useResponseToast } from "../../../hooks/useResponseToast";

const moment = require("moment");

const ImagesEditor = observer((props) => {
  const { updateImages, images } = props;
  const [showImgAddModal, setShowImgAddModal] = useState(false);
  const [skeletonsImage, setSkeletonsImage] = useState(0);
  const { errorToast } = useResponseToast();

  const cloudinaryUpload = (files) => {
    const url = "https://api.cloudinary.com/v1_1/cloud9/image/upload";

    for (let file of files) {
      var data = new FormData();
      data.append("upload_preset", "xphxxaqm");
      data.append("api_key", "675852613763464");
      data.append("file", file);
      data.append("timestamp", moment());

      const config = {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        body: data,
      };

      fetch(url, config).then((res) => {
        if (res.ok) {
          res
            .json()
            .then((r) => {
              const imageUploaded = {
                original_url: r.url,
              };

              const params = { images: [imageUploaded, ...images] };

              updateImages(params, popSkeletonImage);
            })
            .catch((err) => {
              errorToast("Ups! Parece que hubo un problema al subir la imagen");
            });
        } else {
          errorToast("Ups! Parece que hubo un problema al subir la imagen");
        }
      });
    }
  };

  const popSkeletonImage = () => {
    setSkeletonsImage((prev) => prev - 1);
  };

  const pushSkeletonsImages = (skeletonsCount) => {
    setSkeletonsImage((prev) => prev + skeletonsCount);
  };

  const handleImageAdd = (images) => {
    setShowImgAddModal(false);

    pushSkeletonsImages(images.length);

    cloudinaryUpload(images);
  };

  const renderSkeletonsImage = () => {
    let skeletons = [];

    for (var i = 0; i < skeletonsImage; i++) {
      skeletons.push(
        <ImageItem key={`${i}-loading-${moment()}`} loading size="md" />
      );
    }

    return skeletons;
  };

  const handleDeleteImage = (image) => {
    const newImages = images.filter(
      (i) => i.original_url !== image.original_url
    );

    const params = { images: newImages };
    updateImages(params);
  };

  return (
    <>
      <Button
        type="text"
        margin="b-20"
        onClick={() => setShowImgAddModal(true)}
      >
        Agregar imagen
      </Button>

      <ImageItemList size="md">
        {renderSkeletonsImage()}

        {images &&
          images.map((i, index) => {
            let imageUrl = i.image_url ? i.image_url : i.original_url;
            // for mobile we show only three imgs

            return (
              <ImageItem
                key={`${moment()}-${i.original_url}-${i.id}`}
                size="md"
                image={imageUrl}
              >
                <Dropdown direction="bottom-right">
                  <Button
                    type="icon"
                    color="black"
                    icon="options"
                    nointeractions
                  />
                  <DropdownMenu>
                    <DropdownMenuItem
                      icon="delete"
                      id="recoleccion_show_pedidos_masiva_etiquetado_ga4"
                      onClick={() => handleDeleteImage(i)}
                    >
                      Borrar
                    </DropdownMenuItem>
                  </DropdownMenu>
                </Dropdown>
              </ImageItem>
            );
          })}

        {images.length === 0 && skeletonsImage === 0 && <ImageEmptyState />}
      </ImageItemList>

      {showImgAddModal && (
        <ModalV2 onClose={() => setShowImgAddModal(false)} show width="650px">
          <Dropzone
            onFileAdd={handleImageAdd}
            validTypes={["jpg", "jpeg", "png", "svg"]}
          />
        </ModalV2>
      )}
    </>
  );
});

ImagesEditor.propTypes = {
  images: PropTypes.array,
  onChange: PropTypes.func,
};

ImagesEditor.defaultProps = {
  Images: null,
  onChange: null,
};

export default ImagesEditor;
