import {
  DOMAIN_LOCALHOLST,
  DOMAIN_SHIPNOW,
  IS_DEVELOPMENT,
} from "../assets/constants/environment";

export default class CookieClient {
  constructor(name) {
    this.name = IS_DEVELOPMENT ? `${name}_localhost` : name;

    this.defaultOptions = {
      domain: IS_DEVELOPMENT ? DOMAIN_LOCALHOLST : DOMAIN_SHIPNOW,
      path: "/",
    };
  }

  getCookieValue(name) {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            "(?:(?:^|.*;)\\s*" +
              name.replace(/[-.+*]/g, "\\$&") +
              "\\s*\\=\\s*([^;]*).*$)|^.*$"
          ),
          "$1"
        )
      ) || null
    );
  }

  addOptionsInCookie(cookieString, options) {
    for (const property in options) {
      cookieString += `${property}=${options[property]}; `;
    }

    return cookieString;
  }

  /**
   * @return {string} return saved value in the cookie.
   */
  get() {
    return this.getCookieValue(this.name);
  }

  /**
   * @param {string} value value to save in the cookie.
   * @param {boolean} uniqueSet if it is true the cookie is only saved if there is no previous value saved.
   * @param {object} options cookies options.
   */
  set(value, uniqueSet = false, options = this.defaultOptions) {
    if ((!uniqueSet && !this.get()) || !uniqueSet) {
      let cookieString = `${this.name}=${value}; `;
      cookieString = this.addOptionsInCookie(cookieString, options);
      document.cookie = cookieString;
    }
  }

  /**
   * @param {object} options cookies options.
   */
  remove(options = this.defaultOptions) {
    let cookieString = `${this.name}=; max-age=0; `;
    cookieString = this.addOptionsInCookie(cookieString, options);
    document.cookie = cookieString;
  }
}
