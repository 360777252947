import {
  DOMAIN_LOCALHOLST,
  DOMAIN_SHIPNOW,
  IS_DEVELOPMENT_ENV,
} from "../constants/settings.consts";

/**
 * retrieves the value of a cookie by its name.
 * @param {string} name - the name of the cookie to retrieve.
 * @returns {string|null} the value of the cookie if found, otherwise `null`.
 */
export const getCookieValue = (name) => {
  name = IS_DEVELOPMENT_ENV ? `${name}_localhost` : name;

  const value = document.cookie
    .split("; ")
    .find((row) => row.startsWith(name + "="))
    ?.split("=")[1];

  return value ? decodeURIComponent(value) : null;
};

/**
 * sets a cookie with the given name, value, and expiration days.
 * @param {string} name - the name of the cookie to set.
 * @param {string} value - the value of the cookie.
 */
export const setCookieValue = (name, value) => {
  const domain = IS_DEVELOPMENT_ENV ? DOMAIN_LOCALHOLST : DOMAIN_SHIPNOW;

  name = IS_DEVELOPMENT_ENV ? `${name}_localhost` : name;
  value = encodeURIComponent(value);

  document.cookie = `${name}=${value}; path=/; domain=${domain}`;
};

/**
 * deletes a cookie by its name.
 * @param {string} name - the name of the cookie to delete.
 */
export const deleteCookieValue = (name) => {
  const domain = IS_DEVELOPMENT_ENV ? DOMAIN_LOCALHOLST : DOMAIN_SHIPNOW;
  const expires = "max-age=0";

  name = IS_DEVELOPMENT_ENV ? `${name}_localhost` : name;

  document.cookie = `${name}=; ${expires}; path=/; domain=${domain}`;
};

/**
 * manages a cookie to validate if a user has already seen the email confirmation modal
 * associated with their user ID.
 * @param {string} id - Unique user ID related to the email confirmation modal.
 * @param {"add" | "remove"} action - Action to perform on the cookie: "add" to mark as seen, "remove" to delete the record.
 */
export const confirmEmailByCookie = (id, action) => {
  const name = `sn_email_modal_check_${id}`;

  switch (action) {
    case "remove":
      deleteCookieValue(name);
      break;
    case "add":
      setCookieValue(name, "true");
      break;
    default:
      break;
  }
};
