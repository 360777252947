import { Model } from "../lib";

export default class Tariff extends Model {
  constructor(attributes, store) {
    let defaultAttributes = {
      id: null,
      insurance_policy_percentage: null,
      description: null,
      tax_orders_crossdocking_need_packing_label_price: null,
      tax_orders_crossdocking_ready_to_ship_label_price: null,
      tax_orders_crossdocking_unidentified_label_price: null,
      tax_transfers_per_unit_price: null,
      tax_transfers_per_variant_price: null,
      orders_sale_price_max: null,
    };

    super(attributes, store, defaultAttributes);
  }
  urlRoot = "/tariffs";
}
