import React from "react";

import withStyles from "react-jss";

import LogoShipnow from "../../assets/images/shipnow-logo-negro.svg";

const styles = (theme) => ({
  shipnowLogo: {
    width: "150px",
    margin: "auto",
    marginBottom: "20px",
  },
  shipnowLogoImg: {
    marginTop: "116px",
  },
  "@media only screen and (min-width: 1920px)": {
    shipnowLogoImg: {
      marginTop: "80px",
    },
  },
});
const ShipnowLogo = ({ classes }) => {
  return (
    <div className={classes.shipnowLogo}>
      <img
        src={LogoShipnow}
        alt="shipnow logo"
        className={classes.shipnowLogoImg}
      />
    </div>
  );
};

export default withStyles(styles)(ShipnowLogo);
