import { Store } from "../lib";
import { PoS } from "../models";

export default class PoSsStore extends Store {
  model = PoS;

  authorize(id, params) {
    return this.appStore.coreAPIClient.sendRequest(
      `${this.urlRoot}/${id}/authorization`,
      "POST",
      params
    );
  }

  create(params) {
    return this.appStore.coreAPIClient.sendRequest(
      `${this.urlRoot}`,
      "POST",
      params
    );
  }

  fetchPoS(publicationId) {
    return this.appStore.coreAPIClient.sendRequest(
      `${this.urlRoot}/${publicationId}`,
      "GET"
    );
  }
}
