export default class LocalStorageClient {
  namespace = "";

  constructor(namespace) {
    this.namespace = namespace;
  }

  errorMensaje() {
    console.warn(
      "SHIPNOW_DEV: Para una mejor experiencia habilitar permisos de cookies."
    );
  }

  get(path) {
    try {
      return localStorage.getItem(`${this.namespace}_${path}`);
    } catch {
      this.errorMensaje();
    }
  }

  post(path, value) {
    try {
      localStorage.setItem(`${this.namespace}_${path}`, value);
    } catch {
      this.errorMensaje();
    }
  }

  put(path, value) {
    return this.post(path, value);
  }

  delete(path) {
    try {
      localStorage.removeItem(`${this.namespace}_${path}`);
    } catch {
      this.errorMensaje();
    }
  }
}
