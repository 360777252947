import React from "react";
import "whatwg-fetch";

export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
      },
    };
    var minutes = 15;
    var interval = minutes * 60 * 1000;

    setInterval(() => {
      this.checkCache();
    }, interval);
  }

  componentDidMount() {
    this.checkCache();
  }

  checkCache() {
    const currentMetaVersion = process.env.REACT_APP_GIT_SHA;

    const isDevelopment = () => {
      return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
    };

    fetch("/meta.json", { cache: "no-store" })
      .then((response) => response.json())
      .then((meta) => {
        const latestMetaVersion = meta.version;

        this.setState({
          loading: false,
          isLatestVersion:
            latestMetaVersion === currentMetaVersion || isDevelopment(),
        });
      })
      .catch(() => this.setState({ loading: false, isLatestVersion: true })); // in case all fails!
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;

    if (loading) return this.props.fallback;
    if (!loading && !isLatestVersion) {
      refreshCacheAndReload();
    }

    return this.props.children;
  }
}
