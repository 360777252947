//? ESLint is disabled so that the constants remain on a single line.
/* eslint-disable */

export const IS_DEVELOPMENT_ENV = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

//? domains
export const DOMAIN_BETA = "webapp-beta.shipnow.com.ar";
export const DOMAIN_LOCALHOLST = "localhost";
export const DOMAIN_SHIPNOW = "app.shipnow.com.ar";

//? constants for list pages.
export const LIST_PAGE_SIZE = 25;
export const LIST_DUMB = new Array(LIST_PAGE_SIZE).fill({});
export const LIST_REFRESH_INTERVAL = 300000; //? Value in milliseconds, equivalent to 5 minutes. 

//? apis constants
export const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/cloud9/image/upload";
