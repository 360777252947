import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { useStore } from "../../../../hooks/useStore";

import { Form, Formik } from "formik";

import { Box, Button, Flex, StickyPanel, TextV2 } from "shipnow-mercurio";
import { Grid } from "@mui/material";
import {
  CheckBoxFormik,
  InputFormik,
  SelectFormik,
} from "../../../FormikComponents";

import { warehouseAddressSchema } from "./addressSchema";

import { Wrapper } from "./AddressPageForm.styles";

import { SEARCH_CP } from "../../../../assets/constants/links";

export const AddressPageForm = observer((props) => {
  const { address, isLoading, onBack, onSubmit, title } = props;

  const store = useStore();

  const arStates = store.ui.lists.argentinaStates.toJS();
  const hasChatSupport = store.loggedInUser.company.hasChatSupport();

  const handleBack = (values) => {
    onBack && onBack(values);
  };

  return (
    <Formik
      initialValues={{
        check: address.check || false,
        city: address.city || "",
        floor: address.floor || "",
        state: address.state || "",
        street_name: address.street_name || "",
        street_number: address.street_number || "",
        unit: address.unit || "",
        zip_code: address.zip_code || "",
      }}
      onSubmit={onSubmit}
      validationSchema={warehouseAddressSchema}
      validateOnMount
    >
      {({ isValid, values }) => (
        <Form>
          <Wrapper>
            {!!title && (
              <TextV2 margin="b-28" size="title">
                {title}
              </TextV2>
            )}

            <SelectFormik
              label="Provincia"
              margin="b-24"
              maxHeightOptions="320px"
              name="state"
              options={arStates}
            />

            <Grid container spacing={3}>
              <Grid item xs={6} lg={7}>
                <InputFormik
                  label="Localidad o barrio"
                  margin="b-24"
                  name="city"
                />
              </Grid>

              <Grid item xs={3}>
                <InputFormik
                  label="Código postal"
                  margin="b-24"
                  name="zip_code"
                  type="number"
                />
              </Grid>

              <Grid item xs={3} lg={2}>
                <Box margin="t-26">
                  <Button href={SEARCH_CP} type="link">
                    Averiguar mi CP
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <InputFormik label="Calle" margin="b-24" name="street_name" />

            <Grid container spacing={2}>
              <Grid container item xs={6} lg={8}>
                <Grid item xs={6} lg={9}>
                  <InputFormik
                    id="street_number"
                    disabled={values.check}
                    label="Número"
                    margin="b-24"
                    name="street_number"
                    type="number"
                  />
                </Grid>

                <Grid item xs={6} lg={3}>
                  <Box margin="t-20">
                    <CheckBoxFormik label="Sin número" name="check" />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={3} lg={2}>
                <InputFormik
                  isOptional
                  label="Piso"
                  margin="b-24"
                  name="floor"
                  type="number"
                />
              </Grid>

              <Grid item xs={3} lg={2}>
                <InputFormik
                  isOptional
                  label="Depto"
                  margin="b-24"
                  name="unit"
                />
              </Grid>
            </Grid>
          </Wrapper>

          <StickyPanel
            bottom
            className="mt-5 pb-4"
            style={{
              padding: `24px 0 ${hasChatSupport ? "62" : "32"}px 0`,
            }}
          >
            <Flex justifyContent="space-between">
              <Button type="outlined" onClick={() => handleBack(values)}>
                Volver
              </Button>

              <Button disabled={!isValid} isdefault loading={isLoading}>
                Siguiente
              </Button>
            </Flex>
          </StickyPanel>
        </Form>
      )}
    </Formik>
  );
});

AddressPageForm.propTypes = {
  address: PropTypes.object,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};

AddressPageForm.defaultProps = {
  address: null,
  isLoading: false,
  onBack: null,
  onSubmit: null,
  title: null,
};
