import { Model } from "../lib";
import { computed } from "mobx";

export default class PoS extends Model {
  urlRoot = "/stores";

  constructor(attributes, store) {
    let defaultAttributes = {
      store_type: null, // deprecated
      store_type_id: null, // deprecated
      name: null,
      credentials: {},
      url: null,
      stock_sync_own: null,
      seller_id: null,
      token: null, // deprecated
      mercurio: true, // deprecated
      version: 1,
      active: null, // deprecated
      unauthorized: null, // deprecated
      type: {
        code: null,
        created_at: null,
        description: null,
        id: null,
        image_url: null,
        name: null, // idem code, will be deprecated
        updated_at: null,
        url: null,
      },
      show_shipping_estimations: null,
      shipping_options: [
        // {
        //   code: null,
        //   extra_price: null,
        //   extra_days: null,
        // },
        // {
        //   code: null,
        //   extra_price: null,
        //   extra_days: null,
        // },
      ],
      shipping_rules: [
        {
          filter: {
            type: null,
            value: null,
          },
          action_type: null,
          action: {
            category: null,
            warehouse_id: null,
          },
        },
      ],
      status_changed_at: null,
      status: null, // unauthorized - created - active - inactive
      upstream_id: null,
      image_url: null,
      dnd: null,
      created_at: null,
      updated_at: null,
      upstream_token: null,
      picking_preference_own: null,
      facebook_url: null,
      instagram_url: null,
      store_type_code: null, // (WIP backend)
    };

    super(attributes, store, defaultAttributes);
  }

  @computed
  get cookedStatus() {
    switch (this.status) {
      case "active":
        return "Activo";
      case "inactive":
        return "Inactivo";
      case "unauthorized":
        return "Desautorizado";
      case "created":
        return "En espera de conexión";
      default:
        return "";
    }
  }

  @computed
  get cookedImageUrl() {
    return this.image_url ? this.image_url : this.type.image_url;
  }

  @computed
  get statusIcon() {
    if (this.type.code === "manual") {
      return ` `;
    }
    if (!this.active) {
      return "toggle-off";
    }
    return "toggle-on";
  }

  @computed
  get statusColor() {
    if (["unauthorized", "created"].includes(this.status)) {
      return "warning";
    } else if (this.status === "inactive") {
      return "grey";
    }

    return "primary";
  }

  @computed
  get cookedStoreType() {
    switch (this.type.code) {
      case "meli":
        return "MercadoLibre";
      case "magento":
        return "Magento";
      case "prestashop":
        return "PrestaShop";
      case "woocommerce":
        return "WooCommerce";
      case "shopify":
        return "Shopify";
      case "tiendanube":
        return "Tiendanube";
      case "vtex":
        return "VTEX";
      case "mercadoshops":
        return "MercadoShops";
      case "brandlive":
        return "Brandlive";
      case "wix":
        return "Wix";
      default:
        return "";
    }
  }

  @computed
  get isManualIntegration() {
    const type = this.type && this.type.code ? this.type.code : this.store_type;
    return ["wix"].includes(type);
  }

  @computed
  get canHaveShippingRules() {
    const type = this.type && this.type.code ? this.type.code : this.store_type;
    return !["meli", "api", "manual", "brandlive", "netsuits"].includes(type);
  }

  canHaveSincroStock() {
    return ["meli", "tiendanube", "shopify"].includes(this.type.code);
  }

  fromStoresApi() {
    return !["api", "manual"].includes(this.store_type);
  }

  async authorize() {
    return this._store.appStore.pointsOfSale.authorize(
      this.id,
      this.toParams()
    );
  }

  async create() {
    return this._store.appStore.pointsOfSale.create(this.toParams());
  }

  checkStatus(prevStatus) {
    let newStatus;
    if (prevStatus === "Desautorizado") {
      newStatus = "authorize";
    } else if (prevStatus === "Activo") {
      newStatus = "deactivate";
    } else {
      newStatus = "activate";
    }
    return newStatus;
  }

  isMeli() {
    return this.type.code === "meli";
  }

  isTiendanube() {
    return this.type.code === "tiendanube";
  }

  isShopify() {
    return this.type.code === "shopify";
  }
}
