import React, { Component } from "react";
import withStore from "../../../hocs/withStore";
import { observer } from "mobx-react";

import PropTypes from "prop-types";

import { Select, SelectOption, Avatar, TextV2 } from "shipnow-mercurio";

@observer
class CarrierSelect extends Component {
  constructor(props) {
    super(props);

    this.carrierOptions = this.carrierOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(sender, value, name) {
    this.props.onChange && this.props.onChange(sender, value, name);
  }

  carrierOptions() {
    return new Promise((resolve) => {
      this.props.store.carriers.search({ per_page: 50 }).andThen((results) => {
        resolve(
          results.toArray().map((carrier) => (
            <SelectOption
              value={carrier.toJS()}
              display={carrier.name}
              key={carrier.id}
              onChange={this.handleChange}
            >
              <div className="level">
                <div className="level-left">
                  <span className="level-item">
                    <Avatar size="xs" image={carrier.image_url || "invalid"} />
                  </span>
                  <span className="level-item">
                    <TextV2>{carrier.name}</TextV2>
                  </span>
                </div>
              </div>
            </SelectOption>
          ))
        );
      });
    });
  }

  render() {
    const { label, name, maxHeightOptions } = this.props;
    return (
      <Select
        maxHeightOptions={maxHeightOptions}
        label={label}
        name={name}
        options={this.carrierOptions}
        onChange={this.handleChange}
      />
    );
  }
}

CarrierSelect.propTypes = {
  onChange: PropTypes.func,
  warehouseSystemOnly: PropTypes.bool,
  value: PropTypes.any,
  maxHeightOptions: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
};

CarrierSelect.defaultProps = {
  onChange: null,
  warehouseSystemOnly: false,
  value: null,
  maxHeightOptions: "288px",
  name: "carrier_select",
  disabled: false,
  required: false,
  label: "Selecciona un correo",
};

export default withStore(CarrierSelect);
