import {
  axiosCoreClient,
  postCore,
} from "../../../api/services/axiosCore.service";

import { setLocalStorageValue } from "../../common/utils/localStorage.utils";
import { transformRequestRes } from "../../../api/utils/requests.utils";
import { setCookieValue } from "../../common/utils/cookie.utils";

/**
 * Sends a request to check the validity of a invite code.
 * @param {Object} params - The request parameters.
 * @returns {Promise<Object>} A promise resolving to the response data containing the results, headers, and status.
 */
export const checkInviteCode = (params) => {
  const searchString = new URLSearchParams(params);

  return axiosCoreClient
    .get(`/invites?${searchString}`)
    .then((res) => transformRequestRes(res));
};

/**
 * Sends a request to check the validity of a password recovery token.
 * @param {Object} params - The request parameters.
 * @returns {Promise<Object>} A promise resolving to the response data containing the results, headers, and status.
 */
export const checkToken = (params) => {
  return axiosCoreClient
    .post("/users/check_password_recovery", params)
    .then((res) => transformRequestRes(res));
};

/**
 * Sends a request to confirm the user's email.
 * @param {Object} params - The request parameters required for email confirmation.
 * @returns {Promise<Object>} A promise resolving to the response data containing the results, headers, and status.
 */
export const confirmEmail = (params) => {
  return axiosCoreClient
    .post("/users/confirmation", params)
    .then((res) => transformRequestRes(res));
};

/**
 * Sends a request to create user by invite code.
 * @param {Object} params - The request parameters required for create user.
 * @returns {Promise<Object>} A promise resolving to the response data containing the results, headers, and status.
 */
export const createInvitedUser = (body, queryParams) => {
  const searchString = new URLSearchParams(queryParams);

  return axiosCoreClient
    .post(`/users?${searchString}}`, body)
    .then((res) => transformRequestRes(res));
};

/**
 * Sends a request to initiate the password recovery process.
 * @param {Object} params - The request parameters, typically the user's email.
 * @returns {Promise<Object>} A promise resolving to the response data containing the results, headers, and status.
 */
export const recoverPassword = (params) => {
  const options = {
    headers: {
      Mercurio: true,
    },
  };

  return axiosCoreClient
    .post("/users/password_recovery", params, options)
    .then((res) => transformRequestRes(res));
};

/**
 * Sends a request to reset the user's password.
 * @param {Object} params - The request parameters, typically the new password and token.
 * @returns {Promise<Object>} A promise resolving to the response data containing the results, headers, and status.
 */
export const resetPassword = (params) => {
  return axiosCoreClient
    .post("/users/password_update", params)
    .then((res) => transformRequestRes(res));
};

/**
 * Sends a request to log in the user and store authentication details in cookies and local storage.
 * @param {Object} data - The user's login data.
 * @returns {Promise<Object>} A promise resolving to the response data containing the results, headers, and status.
 */
export const userLogin = (data) => {
  const { password, user } = data;

  const credentials = Buffer(`${user}:${password}`).toString("base64");

  const params = {
    fields: "account.onboarding",
  };

  const options = {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  };

  return axiosCoreClient
    .post("/users/authentication/v2", params, options)
    .then((res) => {
      const user = res.data.results ? res.data.results : res.data;

      //? save info in storage
      setCookieValue("sn_loggin_user_token", user.token);
      setCookieValue("sn_loggin_user_id", user.id);
      setLocalStorageValue("sn_salesforce_token", user.salesforce_token);

      return transformRequestRes(res);
    });
};

/**
 * Updates the user's password with the provided credentials.
 * @param {string} id - The user's unique identifier.
 * @param {Object} data - Object containing the user credentials and new password.
 * @returns {Promise} Axios response promise with the result of the password update.
 */
export const updatePassword = (id, data) => {
  const { newPassword, password, user } = data;

  const credentials = Buffer(`${user}:${password}`).toString("base64");

  const params = {
    fields: "account.onboarding",
    password: newPassword,
  };

  const options = {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  };

  return axiosCoreClient.put(`/users/${id}/password`, params, options);
};

/**
 * Logs the user out of the system.
 * @returns {Promise} Axios response promise with the result of the logout request.
 */
export const userLogout = () => {
  return postCore("/users/logout").catch((err) => {
    console.error(err);
  });
};
