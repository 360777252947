import { Account } from "./";

const DEFAULT_ATTRIBUTES = {
  account: null,
  active: false,
  admin: false,
  avatar_url: null,
  confirmation_sent_at: null,
  email: "",
  invite_code: null,
  last_name: "",
  mercurio: null,
  name: "",
  onboarded: false,
  password: null,
  permissions_scope: "",
  role: null,
  type: null,
};

export class User {
  constructor(attributes) {
    const attrs = Object.assign({}, DEFAULT_ATTRIBUTES, attributes);

    Object.keys(attrs).forEach((key) => {
      if (key === "account") {
        this.account = new Account(attrs[key]);
      } else {
        this[key] = attrs[key];
      }
    });
  }

  get fullName() {
    return `${this.name || ""} ${this.last_name || ""}`;
  }

  get hasChatSupport() {
    return this.account.hasChatSupport;
  }

  get isAdmin() {
    return this.account.id === 1;
  }

  get isEmailConfirmed() {
    return !!this.confirmed_at;
  }
}
