/* GoodsIn statuses */
export const GOODSIN_CREATED = "created";
export const GOODSIN_REQUESTED = "requested";
export const GOODSIN_CONFIRMED = "confirmed";
export const GOODSIN_RECEIVED = "received";
export const GOODSIN_PROCESSING = "processing";
export const GOODSIN_COMPLETED = "completed";
export const GOODSIN_CANCELLED = "cancelled";

/* GoodsIn filters */

export const GOODS_IN_ACTIVE_FILTERS = [
  {
    key: "Creado",
    value: GOODSIN_CREATED,
  },
  {
    key: "Solicitado",
    value: GOODSIN_REQUESTED,
  },
  {
    key: "Confirmado",
    value: GOODSIN_CONFIRMED,
  },
  {
    key: "Recibido",
    value: GOODSIN_RECEIVED,
  },
  {
    key: "Procesándose",
    value: GOODSIN_PROCESSING,
  },
];

export const GOODS_IN_FINISHED_FILTERS = [
  {
    key: "Completado",
    value: GOODSIN_COMPLETED,
  },
  {
    key: "Cancelado",
    value: GOODSIN_CANCELLED,
  },
];
