export const getNotDeliveredReason = (notDeliveredReason, orderIsExchange) => {
  let msg;

  switch (notDeliveredReason) {
    case "inoperable_area":
      msg = "No visitamos el domicilio por un error en la dirección.";
      break;
    case "non_existent_address":
      msg = orderIsExchange
        ? "No visitamos el domicilio por un error en la dirección."
        : "No pudimos encontrar tu dirección.";
      break;
    case "incomplete_address":
      msg = "No ubicamos tu domicilio porque faltaban datos.";
      break;
    case "rejected":
      msg = orderIsExchange
        ? "Visitamos tu domicilio, pero no quisieron entregarnos la devolución."
        : "Visitamos tu dirección, pero no aceptaron la entrega.";
      break;
    case "receiver_not_found":
      msg = orderIsExchange
        ? "No encontramos una persona autorizada para entregarnos tu devolución."
        : `Visitamos tu domicilio, pero no había nadie para recibir el pedido.`;
      break;
    case "limited_timeframe":
      msg = "Llegamos fuera del horario de atención.";
      break;
    default:
      msg = "Por un problema operativo, no pudimos visitar tu domicilio.";
  }

  return msg;
};
