import React from "react";
import PropTypes from "prop-types";

import { Box, Button, TextV2 } from "shipnow-mercurio";

const MessagePageStruct = (props) => {
  const { buttonText, image, onClick, text, title } = props;

  return (
    <div className="has-text-centered">
      <Box margin="t-50">{image}</Box>

      <TextV2 align="center" margin="t-32" size="subtitle">
        {title}
      </TextV2>

      <TextV2 align="center" margin="t-8">
        {text}
      </TextV2>

      <Button margin="t-24" onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  );
};

MessagePageStruct.propTypes = {
  buttonText: PropTypes.string,
  image: PropTypes.node,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
};

MessagePageStruct.defaultProps = {
  buttonText: "",
  image: null,
  onClick: null,
  text: "",
  title: "",
};

export default MessagePageStruct;
