import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Button } from "shipnow-mercurio";

const CopyIcon = ({ text, id }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
  };
  return (
    <CopyToClipboard id={id} text={text} onCopy={handleCopy}>
      <Button
        type="icon"
        iconsize="minor"
        icon="copy"
        tooltipoptions={{
          title: copied ? "¡Copiado!" : "Copiar",
          placement: "top",
        }}
      />
    </CopyToClipboard>
  );
};
export default CopyIcon;
