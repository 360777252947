import React from "react";
import { useField } from "formik";
import { Input, InputCalendar, InputArea } from "shipnow-mercurio";
import PropTypes from "prop-types";

export const InputFormik = (props) => {
  const { withClearButton, type } = props;
  const [field, meta, helpers] = useField(props);

  const handleClear = () => {
    helpers.setValue("");
  };

  const handleTouchCalendar = () => {
    helpers.setTouched(true);
  };

  return (
    <>
      {type === "text" ||
      type === "number" ||
      type === "numeric" ||
      type === "password" ? (
        <Input
          errorMsg={meta.touched ? meta.error : ""}
          clearButton={withClearButton ? handleClear : null}
          notFormik={false}
          {...props}
          {...field}
          onBlur={(e) => {
            field.onBlur(e);
            props.onBlur && props.onBlur(e);
          }}
          onChange={(e) => {
            field.onChange(e);
            props.onChange && props.onChange(e);
          }}
        />
      ) : null}

      {type === "calendar" ? (
        <InputCalendar
          errorMsg={meta.touched ? meta.error : ""}
          //? handleChange cambia el valor seleccionando fecha del popup
          handleChange={(e) => {
            props.onChange &&
              props.onChange({ target: { name: props.name, value: e } });
            helpers.setValue(e);
          }}
          handleTouch={handleTouchCalendar}
          notFormik={false}
          {...props}
          {...field}
          onBlur={(e) => {
            field.onBlur(e);
            props.onBlur && props.onBlur(e);
          }}
          onChange={(e) => {
            field.onChange(e);
            props.onChange && props.onChange(e);
          }}
        />
      ) : null}

      {type === "textarea" ? (
        <InputArea
          errorMsg={meta.touched ? meta.error : ""}
          notFormik={false}
          {...props}
          {...field}
          onBlur={(e) => {
            field.onBlur(e);
            props.onBlur && props.onBlur(e);
          }}
          onChange={(e) => {
            field.onChange(e);
            props.onChange && props.onChange(e);
          }}
        />
      ) : null}
    </>
  );
};

InputFormik.propTypes = {
  withClearButton: PropTypes.bool,
  type: PropTypes.oneOf([
    "calendar",
    "text",
    "password",
    "numeric",
    "number",
    "textarea",
  ]),
};

InputFormik.defaultProps = { withClearButton: false, type: "text" };
