import { Model } from "../lib";
import { computed, observable } from "mobx";

export default class Dispatch extends Model {
  urlRoot = "/dispatches";
  @observable warehouse;

  REGEX_VEHICLE_LICENSE_PLATE = /^\w{3}\d{3}|\w{2}\d{3}\w{2}|\w\d{3}\w{3}$/;
  REGEX_DNI = /^\d{8}$/;
  REGEX_CUIT = /^\d{11}$/;

  constructor(attributes, store) {
    let defaultAttributes = {
      carrier_id: null,
      closed_at: null,
      pattern: null,
      driver: {
        vehicle_description: null,
        vehicle_license_plate: null,
        name: null,
        doc_number: null,
        doc_type: null,
      },
      warehouse_id: null,
      carrier: null,
    };

    super(attributes, store, defaultAttributes);
  }

  @computed
  get webURI() {
    return `${this._store.baseWebURI}/${this.id}`;
  }

  @computed
  get statusText() {
    switch (this.status) {
      case "closed":
        return "Despachado";
      case "cancelled":
        return "Cancelado";
      default:
        return "Nuevo";
    }
  }

  @computed
  get statusChangedAt() {
    return this[`${this.status}_at`];
  }

  @computed
  get isOpen() {
    return this.status === "created";
  }

  @computed
  get statusColor() {
    const colors = {
      cancelled: "error",
      closed: "success",
      created: "warning",
    };

    return colors[this.status];
  }

  @computed
  get canAddPattern() {
    return ["5"].includes(this.carrier_id);
  }

  @computed
  get canAddDocs() {
    return ["7", "0"].includes(this.carrier_id);
  }

  @computed
  get driverDocValid() {
    if (!this.driver.doc_type && !this.driver.doc_number && !this.canAddDocs) {
      return true;
    }

    if (this.driver.doc_type && !this.driver.doc_number) {
      return false;
    }

    if (!["CUIL", "CUIT", "DNI"].includes(this.driver.doc_type)) {
      return false;
    }

    const reg = this.driver.doc_type === "DNI" ? /^\d{8}$/ : /^\d{11}$/;

    return reg.test(this.driver.doc_number);
  }

  @computed
  get validVehicleLicensePlate() {
    const regex = /^\w{3}\d{3}|\w{2}\d{3}\w{2}|\w\d{3}\w{3}$/;
    return regex.test(this.driver.vehicle_license_plate);
  }

  @computed
  get closedOrUpdateAt() {
    return this.closed_at || this.cancelled_at || this.updated_at;
  }

  @computed
  get statusIcon() {
    const icons = {
      cancelled: { icon: "cancelled" },
      closed: { icon: "delivered" },
      created: { icon: "created" },
    };

    return icons[this.status];
  }

  fetchDocuments() {
    return this._store.appStore.fecth(`dispatchs/${this.id}/documents`);
  }
}
