/**
 *  function to  retry  react lazy imports
 * @param {*} fn  callback to retry that retruns a promise
 * @param {*} retriesLeft  number of retrys
 * @param {*} interval import intervarl
 */
export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function titleize(sentence) {
  if (!sentence.split) return sentence;
  var _titleizeWord = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    result = [];
  sentence.split(" ").forEach(function (w) {
    result.push(_titleizeWord(w));
  });
  return result.join(" ");
}

export const hashCode = function (str) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export function isEmptyString(string) {
  return !(string || "").trim();
}

// safe object navigation
export function safe(obj) {
  return new Proxy(obj, {
    get: function (target, name) {
      const result = target[name];
      if (!!result) {
        return result instanceof Object ? safe(result) : result;
      }
      return safe({});
    },
  });
}

/**
 *  function capitalize Strings
 * @param {string} string  that will be capitalized
 */
export function capitalizeFirstLetter(string) {
  if (!string) return;

  return string[0].toUpperCase() + string.slice(1);
}

export function setMetaInfo(title, description) {
  let descriptionTag = document.getElementsByName("description")[0];
  if (descriptionTag) descriptionTag.content = description;
  document.title = title;
}

/**
 * Function pluralize Strings
 * @param {string} string  that will be pluralize
 * @param {number} number  the number tha will be compared
 * @param {string} suffix  the suffix tha will be added
 */
export function pluralize(string, number = 1, suffix = "s") {
  return `${number} ${number === 1 ? string : string + suffix}`;
}
