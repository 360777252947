import React, { useState } from "react";
import PropTypes from "prop-types";

import { Dropzone, Flex, IconContainer, TextV2 } from "shipnow-mercurio";

import Papa from "papaparse";

const CsvImporter = (props) => {
  const { onChange } = props;

  const [fileHaveErrors, setFileHaveErrors] = useState(false);

  const isBlankColumn = (key) => {
    return !key || key.startsWith("_");
  };

  const trimObject = (objToTrim) => {
    for (let key in objToTrim) {
      if (isBlankColumn(key)) {
        delete objToTrim[key];
      } else if (objToTrim[key].trim) {
        objToTrim[key] = objToTrim[key].trim();
      }
    }
  };

  const returnData = (result, file) => {
    result.data.forEach((d) => trimObject(d));
    result.meta.fields = result.meta.fields.filter((f) => !isBlankColumn(f));

    onChange && onChange(result, file, result.meta.delimiter);
  };

  const handleFileDrop = (files) => {
    const file = files[0];

    Papa.parse(file, {
      complete: (res) => {
        setFileHaveErrors(res.errors.length > 0);

        if (res.errors.length === 0) {
          returnData(res, file);
        }
      },
      dynamicTyping: false,
      encoding: "utf-8",
      header: true,
      skipEmptyLines: true,
    });
  };

  return (
    <>
      <Dropzone
        icon={props.icon}
        label={props.label}
        onFileAdd={handleFileDrop}
        validTypes={["csv"]}
      />

      {fileHaveErrors && (
        <Flex margin="t-8">
          <IconContainer color="error" icon="error" />

          <TextV2 color="error" margin="l-8">
            El archivo “.csv” tiene errores. Revisalo y vuelve a subirlo.
          </TextV2>
        </Flex>
      )}
    </>
  );
};

CsvImporter.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

CsvImporter.defaultProps = {
  icon: "file",
  label: "Arrastrá acá el archivo",
  onChange: null,
};

export default CsvImporter;
