import React from "react";
import ReactDOM from "react-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2, //? retry count if the request fails.
      refetchOnWindowFocus: false, //? do not refetch on window focus.
    },
  },
});

ReactDOM.render(
  <BrowserRouter basename="/">
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
