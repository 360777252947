import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "shipnow-mercurio";
import { useResponseToast } from "../../../hooks/useResponseToast";

import { useStore } from "../../../hooks/useStore";

const GetTariffBtn = ({ account, callback, ...others }) => {
  const store = useStore();

  const { loading, setLoading, errorToast } = useResponseToast();
  const [tarrifUrl, setTarrifUrl] = useState(null);

  const fetchTarrif = async () => {
    callback && callback();
    if (tarrifUrl) {
      window.open(tarrifUrl, "_blank");
    } else {
      setLoading(true);

      const filters = {
        per_page: 1,
        with_warehouse_system: true,
      };

      await store.warehouses
        .fetchWarehouseAccount(account.id, filters)
        .andThen((res, err) => {
          if (!res || res._status === "error") {
            return errorToast(
              "Hubo un error en nuestro sistema. Por favor, regresa más tarde."
            );
          }

          const tariff = res.items[0]?.current_tariff;
          const url = account.isCrossdocking()
            ? tariff?.file_crossdocking_url
            : tariff?.file_fullfilment_url;

          if (url) {
            setTarrifUrl(url);
            window.open(url, "_blank");
          }
        });
      setLoading(false);
    }
  };

  return (
    <Button
      onClick={fetchTarrif}
      type="text"
      disabled={loading}
      loading={loading}
      {...others}
    >
      Consultar tarifario
    </Button>
  );
};

GetTariffBtn.propTypes = {
  account: PropTypes.object,
  callback: PropTypes.func,
};

export default GetTariffBtn;
