import React from "react";

import { Button } from "shipnow-mercurio";

const EditButton = (props) => {
  const { disabled, handleEdit, id, margin } = props;

  return (
    <Button
      disabled={disabled}
      icon="edit"
      iconsize="minor"
      id={id}
      margin={margin}
      onClick={handleEdit}
      textsize="captionRegular"
      type="text"
    >
      Editar
    </Button>
  );
};

export default EditButton;
