/**
 * retrieves pagination data from the response headers.
 * @param {Object} headers - the HTTP response headers.
 * @returns {Object} object containing pagination data.
 */
export const getPaginateData = (headers) => {
  const page = Number(headers["x-page"]);
  const total = Number(headers["x-total"]);
  const perPage = Number(headers["x-per-page"]);
  const pages = Math.ceil(total / perPage);

  return {
    page: page,
    pages: pages,
    total: total,
    perPage: perPage,
  };
};
