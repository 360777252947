/**
 * Extracts only the relevant fields from the HTTP response.
 * @param {Object} res - The HTTP response object.
 * @returns {Object} An object containing the extracted results, headers, and status.
 */
export const transformRequestRes = (res) => {
  return {
    results: res.data.results ? res.data.results : res.data,
    headers: res.headers.toJSON(),
    status: res.status,
  };
};
