import React from "react";
import { Button } from "shipnow-mercurio";
const CancelarIconButton = ({ onCancel }) => {
  return (
    <Button className="mt-3" icon="cancel" type="text" onClick={onCancel}>
      Cancelar
    </Button>
  );
};

export default CancelarIconButton;
