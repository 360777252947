import React from "react";
import { Flex, TextV2 } from "shipnow-mercurio";

export const ImageEmptyState = () => {
  return (
    <Flex
      width="100%"
      borderColor="blackBorder"
      borderStyle="solid"
      borderWidth="1px"
      height="350px"
      margin="b-40 t-20 l-24"
      style={{
        borderRadius: "4px",
      }}
    >
      <TextV2 color="blackMediumEmphasis">Aún no se agregó una imagen.</TextV2>
    </Flex>
  );
};
