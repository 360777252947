import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";

import { Grid } from "@mui/material";
import debounce from "lodash/debounce";
import { Form, Formik } from "formik";
import {
  InputFormik,
  PhoneInputFormik,
  SelectFormik,
} from "../../FormikComponents";
import { addressValidation } from "./yupValidation/adressValidation";
import { useStore } from "../../../hooks/useStore";

const AddressEdit = observer((props) => {
  let invalidFields = [];
  const { hidePhone, address } = props;
  const store = useStore();

  const analyzeAddress = () => {
    if (!props.geoCoding) return;

    const address = props.address;

    if (!address) return;

    if (store.geocoder.isValidAddress(address)) {
      store.geocoder
        .parse({
          city: address.city,
          country: address.country,
          floor: address.floor,
          state: address.state,
          street_name: address.street_name,
          street_number: address.street_number,
          unit: address.unit,
          zip_code: address.zip_code,
        })
        .then((result) => {
          const data = result.results;

          address["lat"] = data ? data.lat : null;
          address["lon"] = data ? data.lon : null;

          handleChange &&
            handleChange({ target: { name: "position", value: address } });
        });
    } else {
      return;
    }
  };

  const debounceAddress = debounce(analyzeAddress, 1000);

  const checkAddress = (name, address) => {
    if (
      ["state", "zip_code", "city", "street_name", "street_number"].includes(
        name
      )
    ) {
      debounceAddress();
    }
  };

  useEffect(() => {
    const { address } = props;

    if (!address.lat || !address.lon) {
      analyzeAddress();
    }
    // eslint-disable-next-line
  }, []);

  const updateAddress = (address, name, value) => {
    address[name] = value;

    if (address.line && !address.street_name) {
      address.street_name = address.line;
    }

    if (name === "country") {
      address.state = null;
    }

    address.line = null;
    address.address_line = null;
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    let _value = value;
    let address = props.address;

    if (name === "country") {
      if (value) {
        _value = countriesList.find((c) => c.code === value);

        if (address[name].code !== _value.code) {
          updateAddress(address, name, {
            name: _value.name,
            code: _value.code,
          });

          analyzeAddress();
        }
      }
    } else if (name !== "position") {
      if (address[name] !== _value) {
        updateAddress(address, name, _value);

        if (!!address.zip_code) {
          if (address.zip_code.length > 3) {
            checkAddress(name, address);
          }
        }
      }
    }

    if (name === "zip_code") {
      if (validateZipCode(value, address.country.code)) {
        invalidFields = invalidFields.filter((field) => field !== name);
      } else {
        invalidFields.push(name);
      }
    }

    props.onChange && props.onChange(address, value, name, invalidFields);
  };

  const validateZipCode = (value, countryCode) => {
    if (countryCode === "AR") {
      const pattern = /^\d*$/;
      return !!value && pattern.test(value);
    } else {
      return !!value;
    }
  };

  const getCountriesList = () => {
    if (!countriesList) {
      const list = store.ui.lists.countries;

      if (list.isOk()) {
        countriesList = list
          .toArray()
          .map((country) => ({ name: country.name, code: country.code }));
      } else {
        return [
          {
            name: "Argentina",
            code: "AR",
          },
        ];
      }
    }

    return countriesList;
  };

  let countriesList = getCountriesList();
  const arStates = store.ui.lists.argentinaStates.toJS();

  return (
    <Formik
      initialValues={{
        country: address.country && address.country.code,
        state: address.state,
        street_name: address.street_name || address.line,
        street_number: address.street_number,
        floor: address.floor,
        unit: address.unit,
        zip_code: address.zip_code,
        city: address.city,
        phone: address.phone,
      }}
      validationSchema={addressValidation}
    >
      {(props) => (
        <Form>
          {" "}
          <Grid container style={{ padding: "12px 12px 12px 0px" }}>
            <Grid item xs={6}>
              <SelectFormik
                padding="r-20"
                minWidth="20px"
                maxHeightOptions="290px"
                label="País"
                options={countriesList}
                name="country"
                keyAttr="name"
                valueAttr="code"
                key={address.country.code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              {!address.country || address.country.code === "AR" ? (
                <SelectFormik
                  padding="r-7"
                  label="Provincia"
                  maxHeightOptions="290px"
                  minWidth="20px"
                  name="state"
                  options={arStates}
                  onChange={handleChange}
                />
              ) : (
                <InputFormik
                  padding="r-7"
                  margin="y-0"
                  label="Provincia"
                  name="state"
                  onChange={handleChange}
                />
              )}
            </Grid>
          </Grid>
          <Grid container style={{ padding: "12px 12px 12px 0px" }}>
            <Grid item xs={6}>
              <InputFormik
                padding="r-20"
                margin="y-0"
                label="Calle"
                name="street_name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <InputFormik
                padding="r-20"
                margin="y-0"
                label="Número"
                name="street_number"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <InputFormik
                padding="r-20"
                margin="y-0"
                label="Piso"
                isOptional
                name="floor"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <InputFormik
                padding="r-7"
                margin="y-0"
                label="Unidad"
                isOptional
                name="unit"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container style={{ padding: "12px 12px 12px 0px" }}>
            <Grid item xs={4}>
              <InputFormik
                padding="r-20"
                margin="y-0"
                label="Código Postal"
                type="number"
                name="zip_code"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={8}>
              <InputFormik
                padding="r-7"
                margin="y-0"
                label="Ciudad / Barrio"
                name="city"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {!hidePhone && (
            <Grid container style={{ padding: "12px 12px 12px 0px" }}>
              <Grid item xs={4}>
                <PhoneInputFormik
                  isOptional
                  label="Teléfono"
                  name="phone"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
});

AddressEdit.propTypes = {
  onChange: PropTypes.func,
  address: PropTypes.object,
  hidePhone: PropTypes.bool,
  geoCoding: PropTypes.bool,
};

AddressEdit.defaultProps = {
  onChange: null,
  onPositionComputed: null,
  address: null,
  hidePhone: false,
  geoCoding: true,
};

export default AddressEdit;
