import { Model } from "../lib";
import { computed } from "mobx";

export default class StoreType extends Model {
  urlRoot = "/store_types";

  @computed
  get isSupportingPublications() {
    return ["meli", "woocommerce", "tiendanube", "shopify"].includes(this.name);
  }
}
