import { Model } from "../lib";
import { action } from "mobx";
export default class Movement extends Model {
  urlRoot = "/movements";

  isInitialBalance() {
    return this.movement_type.code === "SALDO INICIAL";
  }

  isPaymentType() {
    return this.movement_type.category === "payment";
  }

  isOverduable() {
    return ["invoice", "debit_note"].includes(this.movement_type.category);
  }

  parseTags(statsTags) {
    // Los posibles tags son: ["paid"], ["unpaid"], ["partially_paid"], ["unpaid", "overdue"], ["partially_paid", "overdue"]
    const movement_tags = statsTags?.find((tag) => tag.movement_id === this.id);
    if (!movement_tags) return null;
    if (
      movement_tags.tags.includes("unpaid") &&
      movement_tags.tags.includes("overdue")
    ) {
      return ["overdue"];
    }

    return movement_tags.tags;
  }

  @action
  mercadoPago(filters) {
    const url = "/payment_methods";
    return this._store.save(this, url, true, filters);
  }
}
