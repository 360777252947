import { Model } from "../lib";
import { computed, observable, action } from "mobx";

export default class CheckIn extends Model {
  @observable status;

  constructor(attributes, store) {
    let defaultAttributes = {
      order_id: "",
      created_at: null,
      completed_at: null,
      received_at: null,
      updated_at: null,
      processing_at: null,
      quality: "",
      order: null,
      status: "",
      scheduled_action: "",
      action_resource: null,
    };

    super(attributes, store, defaultAttributes);
  }

  urlRoot = "/check_ins";
  returnLabel;
  @observable currentOrder;

  @computed
  get action_stock() {
    return this.scheduled_action === "stock";
  }

  @computed
  get action_return() {
    return this.scheduled_action === "return";
  }

  @computed
  get action_resend() {
    return this.scheduled_action === "resend";
  }

  @computed
  get action_pickup() {
    return this.scheduled_action === "pickup";
  }

  @computed
  get processed() {
    return !!this.received_at;
  }

  fetchReturnLabel() {
    if (this.returnlabel) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.coreAPIClient
        .search(`${this.urlRoot}/${this.id}/labels`)
        .then(
          (res) => {
            this.returnLabel = res.results.zpl;

            this.endUpdate();
          },
          (err) => {
            this.endUpdate();
          }
        );
    }
    return this;
  }

  @action
  afterSetData() {
    if (this.order && this._store) {
      this.currentOrder = this._store.appStore.orders.getFromData(this.order);
    }
  }
}
