import { computed } from "mobx";

import { Model } from "../lib";

export default class ReturnsGroup extends Model {
  urlRoot = "/returns/return-group";

  constructor(attributes, store) {
    let defaultAttributes = {
      account_id: null,
      id: null,
      goods_in_id: null,
      next_return_group_id: null,
      warehouse_id: null,
    };

    super(attributes, store, defaultAttributes);
  }

  webURI(id) {
    return id
      ? `/app/returns/returns-group/${id}`
      : "/app/returns/returns-group";
  }

  STATUSES_OPTIONS = {
    new: {
      icon: "new",
      label: "Nuevo",
      status: "warning",
    },
    assigned_pickup: {
      icon: "calendar",
      label: "Recolección asignada",
      status: "grey",
    },
    ready_to_ship: {
      icon: "calendar",
      label: "A despachar",
      status: "highlight",
    },
    shipped: {
      icon: "shippingFast",
      label: "Despachado",
      status: "highlight",
    },
    returned: {
      icon: "delivered",
      label: "Devuelto",
      status: "success",
    },
    not_returned: {
      icon: "notDelivered",
      label: "No devuelto",
      status: "error",
    },
  };

  @computed
  get statusOptions() {
    return this.STATUSES_OPTIONS[this.status];
  }
}
