//? Se deshabilita eslint para que los linsk queden en la misma linea que los nombres de las variables.
/* eslint-disable */

// webapp pages.
export const SUPPORT_PAGE = "https://app.shipnow.com.ar/app/support";

// generic links.
export const PROHIBITED_ITEMS_DOC = "https://shipnow.notion.site/Qu-productos-transportamos-6b6d3e2c369b47a2aa951640e82dd3ad";
export const PRIVACY_POLICIES = "https://shipnow-assets-production.s3.amazonaws.com/webapp/politicas-privacidad.pdf";
export const TEARMS_AND_CONDITIONS = "https://shipnow-assets-production.s3.amazonaws.com/webapp/terminos-y-condiciones.pdf";

// Guías de preguntas frecuentes.
export const FAQs = "https://shipnow.notion.site/18bf196bd1344262a9a3f72341757e1e?v=f9f9911ea53e4e508ae5639eac8ef6fb";

// Guías de tipos de operaciones.
export const INSURANCE_POLICY_FAQS = "https://shipnow.notion.site/En-qu-consiste-el-seguro-de-env-os-45fef4a0350944179f909e25e44b8d89";
export const INSURANCE_FAQ = "https://shipnow.notion.site/Qu-cubre-el-seguro-de-env-os-45fef4a0350944179f909e25e44b8d89";

// FAQS
export const BUYER_FAQS = "https://www.shipnow.com.ar/ayuda";
export const FAQS_ABOUT_US = "https://shipnow.notion.site/Sobre-el-servicio-de-shipnow-2f5009fbc55e414e9faf6b01be4422f0";
export const FAQS_ACCOUNT_ACTIVATION = "https://shipnow.notion.site/Activaci-n-de-cuenta-d48fdffcc1fc47a8860289a8fa8076e1";
export const FAQS_HOW_TO_OPERATE = "https://shipnow.notion.site/C-mo-empezar-a-operar-9d8295cc6115423889859c3745f4d4fe";
export const FAQS_INTEGRATIONS = "https://shipnow.notion.site/Integraciones-f594547a572a49f0a452a898631b7278";
export const FAQS_GOODSIN = "https://shipnow.notion.site/Recolecciones-e-ingresos-5f8adb1226594d26b956d198be31dff2";
export const FAQS_ORDERS = "https://shipnow.notion.site/Pedidos-2b6242c6b2dd4a838dc071bbba07ae56";
export const FAQS_ORDERS_IMPORT = "https://shipnow.notion.site/C-mo-importar-pedidos-desde-un-Excel-o-csv-9beae14fa2ca4d94bfe83d47b44d5a34";
export const FAQS_GOODSIN_IMPORT = "https://shipnow.notion.site/Agregar-un-pedido-a-una-recolecci-n-eeb44b4455db45b9afd9d1957046975f";
export const FAQS_RETURNS = "https://shipnow.notion.site/Devoluciones-2e6196f10b214dbd91328ade69313a85";
export const FAQS_RETURNS_HOW_WORKS='https://shipnow.notion.site/Estados-de-una-devoluci-n-en-la-plataforma-78f73560bfc24f43a4e23785aa30081b'
export const FAQS_LABELS = "https://shipnow.notion.site/Etiquetas-934dbb6fd6ef44a1b39f18dec3c85d7e";
export const FAQS_FOLLOW_ORDER = "https://shipnow.notion.site/Seguimiento-del-pedido-350ba7d239f3415cb79ace7fe4c199fe";
export const FAQS_INVENTORY = "https://shipnow.notion.site/Inventario-a29ce4d34aab4bc7ab4ee03772447400";
export const FAQS_SHIPPING = "https://shipnow.notion.site/Env-os-00ac857239cb4164841a0b6dcc89e7c6";
export const FAQS_BILLING = "https://shipnow.notion.site/Facturaci-n-55ac0132f1ca4706bad23ea140b4d064";
export const FAQS_SECURITY = "https://shipnow.notion.site/Seguro-de-env-os-4648ac2ba02a4c1a912e2220ee321e9c";
export const FAQS_SETTINGS = "https://shipnow.notion.site/Configuraci-n-78e16ac59752461b9dec32a6b464123c";
export const FAQS_ESTIMATED_DELIVERY = "https://shipnow.notion.site/Contacto-y-horarios-de-atenci-n-37592746f3be438c85b90b2e55e6dfb5";
export const FAQS_CPS_RETURN = "https://shipnow.notion.site/En-qu-zonas-hacemos-devoluciones-b8a0cea8b2834f85a1bc374852df434f"
export const FAQS_IIBB = "https://shipnow.notion.site/C-mo-descargar-el-comprobante-de-inscripci-n-a-IIBB-7f6dfc0e331b4750b6f1c02ad6453b73";
export const FAQS_GOODINS_LABELS ="https://www.notion.so/shipnow/C-mo-etiquetar-un-pedido-para-una-recolecci-n-a5643dd39079436dbb504f08040f38f3?pvs=4";

export const ZONAS_DEVOLUCIONES= 'https://shipnow.notion.site/En-qu-zonas-hacemos-devoluciones-15bdae95141c48cc832461ef5da0e4cc';
export const MODALIDADES_DEVOLUCIONES='https://shipnow.notion.site/Modalidades-de-una-devoluci-n-1751d0ad234647a59d67805acba7c641';

// Guías crossdocking y fullfilment.
export const SHIPCROSS = "https://shipnow.notion.site/Qu-es-Shipcross-8fabf8e1516345d9a5c946693271ba17";
export const SHIPFULL = "https://shipnow.notion.site/Qu-es-Shipfull-c39245bf56dd484ab56ab16c63ea68d5";

// Guías variantes y pedidos.
export const TRANSFER_VARIANTS_GUIDE = "https://shipnow.notion.site/C-mo-crear-un-ingreso-de-variantes-62365c487e2843b8ad03bbabad20814a";
export const PICKUP_ORDERS_GUIDE = "https://shipnow.notion.site/C-mo-crear-una-recolecci-n-de-pedidos-a2aed545c1fb43cbbaac6c809e6b9cb3";

// NPS links - reseñas del tracking.
export const GOOGLE_OPINION = "https://g.page/r/CTGYkVBn-S8JEAg/review";


//Guías de la home
export const ABOUT_OUR_SERVICES = "https://shipnow.notion.site/Sobre-el-servicio-de-shipnow-2f5009fbc55e414e9faf6b01be4422f0";

export const ACCOUNT_ACTIVATION = "https://shipnow.notion.site/Activaci-n-de-cuenta-d48fdffcc1fc47a8860289a8fa8076e1";

export const HOW_TO_OPERATE = "https://www.notion.so/shipnow/C-mo-empezar-a-operar-9d8295cc6115423889859c3745f4d4fe";

// Guías de tabla de recolecciones
export const TAG_GUIDE_ORDERS = "https://shipnow.notion.site/C-mo-etiquetar-un-pedido-para-una-recolecci-n-a5643dd39079436dbb504f08040f38f3";
export const TAG_GUIDE_VARIANTS = "https://shipnow.notion.site/C-mo-etiquetar-una-variante-para-un-ingreso-10aad7fcfe904a59b3e8048f7921eeb3";

// Integración de tiendas.
export const PLUGGIN_FILE = "https://shipnow-assets-production.s3.amazonaws.com/stores/plugins/shipnow-shipping-1.01.zip";

export const MAGENTO_SHIPPING_RATES = "https://docs.magento.com/user-guide/shipping/shipping-table-rate.html?lang=es";

export const MELI_FLEX_REQUIREMENTS = "https://www.notion.so/Requisitos-para-activar-Flex-clientes-Shipfull-f42d619f20db4b66a25c9ba8c91acfa3";
export const MELI_HOW_ACTIVE_FLEX = "https://shipnow.notion.site/C-mo-configurar-Env-os-Flex-f00be38e1ccf4e5a927818ef69a7aee6";

export const PRESTASHOP_CONFIG = "https://help.printful.com/hc/es/articles/360014068859-How-do-I-configure-shipping-for-PrestaShop-";
export const PRESTASHOP_GUIDE = "https://shipnow.notion.site/Integraci-n-con-Prestashop-5dfc436f66ff4c59a5e1b342c816dfa7";

export const SHOPIFY_SHIPPING = "https://help.shopify.com/es/manual/shipping/setting-up-and-managing-your-shipping/enabling-shipping-carriers";
export const SHOPIFY_CONFIG_GUIDE = "https://shipnow.notion.site/Opciones-de-env-o-Shopify-9c7d42a8048a480fa23862a091bc50e2";
export const SHOPIFY_CONFIG = "https://help.shopify.com/es/manual/shipping/setting-up-and-managing-your-shipping/setting-up-shipping-rates";
export const SHOPIFY_LOGIN = "https://accounts.shopify.com/lookup?rid=7e7a4766-247b-4c85-9558-12e9b0f44f20&verify=1718301426-ihdGvJYQpAKTNscL1faAhRe3tbFCTwb39r%2FYOLs4EPM%3D";
export const SHOPIFY_INTEGRATION_GUIDE = "https://www.notion.so/shipnow/Sincronizaci-n-de-stock-con-Shopify-709c95fe21124c21be25ba1ba9dc8a4e";

export const TIENDA_NUBE_CONFIG_GUIDE = "https://shipnow.notion.site/Opciones-de-env-o-Tiendanube-4e295836ea9844279e24a671ac1e2334";
export const TIENDA_NUBE_PLANS = "https://www.tiendanube.com/planes-y-precios";
export const TIENDA_NUBE_LOGIN = "https://www.tiendanube.com/login";
export const VTEX_SHIPPING_RATES = "https://help.vtex.com/es/tutorial/shipping-rate-template--tutorials_127";

export const WIX_CONFIG = "https://support.wix.com/es/article/wix-stores-a-guide-to-setting-up-shipping-delivery-and-pickup";

export const WOO_PLUGGIN_CONFIG_GUIDE = "https://shipnow.notion.site/Opciones-de-env-o-WooCommerce-51a88610448f41fa8a6dcef15256986c";
export const SHOPIFY_GUIDE = "https://shipnow.notion.site/Shopify-acf128a5609e4d7d842699d16d8c1713";
export const MAGENTO_GUIDE = "https://shipnow.notion.site/Integraci-n-con-Magento-948bfb81e26d4e14882b3c59e79c2d20";
export const API_GUIDE = "https://shipnow.docs.apiary.io";
export const VTEX_GUIDE =  "https://shipnow.notion.site/Vtex-f9563a1a9ff74ce09f3b9e33e50946db";
export const VTEX_EXTERNAL_GUIDE = "https://help.vtex.com/es/tutorial/application-keys--2iffYzlvvz4BDMr6WGUtet";
export const VTEX_SIGNIN = "https://apps.vtex.com/authenticate";
export const MELI_GUIDE = "https://shipnow.notion.site/Mercado-Libre-efefbdb49abb4713ab2a3c3595cc7bf2";
export const MELI_SIGNIN = "https://www.mercadolibre.com/jms/mla/lgz/msl/login/H4sIAAAAAAAEAzWNsQ7CMAxE_8VzRSUGJDKy8APskWndYuE0UeLSoqr_joPU8e7e3W0gceTJ6zcROKA1CXes0EAS1CHm4Lm3IIhZhZUOiRXBjIGUcgG31aGR-htZqU4NKIUMwllffpC4mPf_Mo-Lp9V6E4pf6PlhqunRGKOJl2pybRtI-CTYntdLfNyvsDeGFfWasXuD0zzT_gP2ZZqNxAAAAA/user";
export const TIENDA_NUBE_GUIDE = "https://shipnow.notion.site/Tiendanube-47f38eccaeba496494ad9237ee1b3648";
export const WOOCOMMERCE_GUIDE = "https://shipnow.notion.site/WooCommerce-6aac25d512c2408e894b8f3fc7304d7f";



// Correo Argentino CPs
export const SEARCH_CP = "https://www.correoargentino.com.ar/formularios/cpa";