import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const Countdown = ({ deadline, noTime }) => {
  const [timer, setTimer] = useState("00:00");

  const intervalRef = useRef(null);

  const setRemainingTime = useCallback(
    (endTime) => {
      const total = endTime - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);

      if (total > 0) {
        return (
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
        );
      }

      // finish count.
      clearInterval(intervalRef.current);
      noTime();
      return "00:00";
    },
    [noTime]
  );

  const startCountdown = useCallback(
    (endtime) => {
      const time = setRemainingTime(endtime);
      setTimer(time);

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      const id = setInterval(() => {
        const time = setRemainingTime(endtime);
        setTimer(time);
      }, 1000);

      intervalRef.current = id;
    },
    [setRemainingTime]
  );

  useEffect(() => {
    startCountdown(deadline);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [deadline, startCountdown]);

  return <>{timer}</>;
};

Countdown.propTypes = {
  deadline: PropTypes.number,
  noTime: PropTypes.func,
};

Countdown.defaultProps = {
  deadline: 0,
  noTime: null,
};

export default Countdown;
