import React from "react";
import PropTypes from "prop-types";

import { Loader } from "shipnow-mercurio";

const GenericLoader = (props) => {
  const { fullScreen } = props;

  return (
    <Loader
      className={fullScreen ? "fullscreen" : null}
      label="Cargando..."
      animation="spin"
    />
  );
};

GenericLoader.propTypes = {
  fullScreen: PropTypes.bool,
};

GenericLoader.defaultProps = {
  fullScreen: true, //TODO: invertir este valor y cambiar en todos los lados que se usa este componente.
};

export default GenericLoader;
