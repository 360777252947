import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { TextInput } from "shipnow-mercurio";
import withStore from "../../../hocs/withStore";

@observer
class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
  }

  handleChange(sender, value, name) {
    this.setState({ inputValue: value });
  }

  handleKeyPress(e) {
    const key = e.charCode;
    if (key === 13 /* Enter */) {
      this.handleAccept();
    } else if (key === 9 /* Tab */) {
    }
  }

  handleAccept() {
    const inputValue = this.state.inputValue || "";

    this.props.onAccept && this.props.onAccept(inputValue, "search_bar");

    this.setState({ inputValue: "" });
  }

  render() {
    return (
      <TextInput
        className="is-fullwidth"
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        onKeyPress={this.handleKeyPress}
        value={this.state.inputValue}
        disabled={this.props.failed}
        valid={
          this.props.failed
            ? {
                type: "error",
                message: "Hubo un error con alguno de los pedidos escaneados",
              }
            : null
        }
        autofocus={true}
      />
    );
  }
}

SearchBar.propTypes = {
  onAccept: PropTypes.func,
  onChange: PropTypes.func,
  failed: PropTypes.bool,
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  onAccept: null,
  onChange: null,
  failed: false,
  placeholder: "Escanear o Buscar",
};

export default withStore(SearchBar);
