import { Store } from "../lib";
import { Kit } from "../models";

export default class KitsStore extends Store {
  model = Kit;
  baseWebURI = "/app/inventory/kits";

  archive(kit) {
    const params = {
      id: kit.id,
      archived: !kit.archived,
    };

    return this.appStore.coreAPIClient.put(`/variants`, params).then((res) => {
      if (res && res["results"]) {
        kit.set(res["results"]);
        kit.endUpdate();
      }
    });
  }

  update(id, params) {
    return this.appStore.coreAPIClient.sendRequest(
      `/variants/${id}`,
      "PUT",
      params
    );
  }

  getImages(id) {
    return this.appStore.coreAPIClient.sendRequest(
      `/variants/${id}/images`,
      "GET"
    );
  }
}
