import { pick, isEqual } from "lodash";

/**
 * Sums up all numeric attributes of an object.
 *
 * @param {Object} obj - The input object.
 * @returns {number} - The sum of all numeric attributes.
 */
export const sumObjectAttributes = (obj) => {
  if (obj === null) return 0;

  const attributeValues = Object.values(obj);

  const sum = attributeValues.reduce((acc, currentValue) => {
    if (typeof currentValue === "number") {
      return acc + currentValue;
    }

    return acc;
  }, 0);

  return sum;
};

/**
 * Compares specified keys of two objects for equality.
 *
 * @param {Object} a - The first object for comparison.
 * @param {Object} b - The second object for comparison.
 * @param {Array<string>} keys - The keys to compare between the two objects.
 * @returns {boolean} - `true` if the specified keys are equal, otherwise `false`.
 */
export const compareData = (a, b, keys) => {
  return isEqual(pick(a, keys), pick(b, keys));
};

/**
 * Checks if all values in the object are empty (null).
 *
 * @param {Object} obj - The object to check for empty values.
 * @returns {boolean} - `true` if all values are empty (null), otherwise `false`.
 */
export const emptyValuesObj = (obj) => {
  for (const property in obj) {
    if (obj[property] !== null) return false;
  }

  return true;
};

/**
 * Checks if a given object is empty.
 *
 * @param {Object} obj - The object to be checked.
 * @returns {boolean} Returns true if the object is empty, false if it has properties.
 */
export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};
