import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { TextV2, Time } from "shipnow-mercurio";

export const UpdatedAtFooter = observer((props) => {
  const { model } = props;

  return (
    <TextV2
      align="center"
      color="blackDisabled"
      margin="b-24 t-40"
      size="captionRegular"
    >
      Actualizado por última vez el &nbsp;
      {model && model.isOk() && (
        <Time
          text={{
            as: "span",
            color: "blackDisabled",
            size: "captionRegular",
          }}
          value={model.updated_at}
          type="dateWithTime"
        />
      )}
    </TextV2>
  );
});

UpdatedAtFooter.propTypes = {
  model: PropTypes.object,
};

UpdatedAtFooter.defaultProps = {
  model: null,
};
