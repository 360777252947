import React from "react";
import { Flex } from "shipnow-mercurio";
const CardTItleLayout = ({ children }) => {
  return (
    <Flex
      style={{ height: "38px" }}
      margin="b-20"
      justifyContent="space-between"
    >
      {children}
    </Flex>
  );
};

export default CardTItleLayout;
