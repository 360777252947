import * as yup from "yup";
import { isValidNumber } from "libphonenumber-js";

export const addressValidation = () =>
  yup.object().shape({
    country: yup.string().required(),
    state: yup.string().required(),
    street_name: yup.string().required("Ingresa una calle. ").nullable(),
    street_number: yup
      .string()
      .required("Ingresa un número de calle.")
      .nullable(),
    zip_code: yup.number().required("Ingresa un código postal.").nullable(),
    city: yup.string().required("Ingresa una ciudad o barrio.").nullable(),
    phone: yup
      .string()
      .test(
        "valid",
        "Verifica que el número ingresado incluya el código de área y pertenezca al país seleccionado.",
        (value) => {
          if (!value) return true;
          return isValidNumber(value);
        }
      )
      .nullable(),
  });
