import { Store } from "../lib";
import { Warehouse } from "../models";

export default class WarehousesStore extends Store {
  model = Warehouse;
  baseWebURI = "/app/warehouses";

  searchStockStasts(warehouse_id, params) {
    let fetchParams = Object.assign({}, params);

    return this.fetch(`${warehouse_id}/stocks/stats`, fetchParams);
  }

  searchWarehousesBySellerId(sellerId, params) {
    return this.appStore.coreAPIClient.search(
      `/accounts/${sellerId || "me"}/warehouse_accounts`,
      params
    );
  }

  fetchWarehouseAccount(sellerId, filters, refresh) {
    return this.fetch(
      `warehouse_accounts`,
      filters,
      {},
      refresh,
      `/accounts/${sellerId}`
    );
  }

  archive(warehouse) {
    const params = {
      id: warehouse.id,
      archived: true,
    };

    return this.appStore.coreAPIClient
      .put(this.modelRoot, params)
      .then((res) => {
        if (res && res["results"]) {
          warehouse.set(res["results"]);
          this.items.addOrUpdateModel(warehouse);
          warehouse.endUpdate();
        }
      });
  }

  unarchive(warehouse) {
    let params = {
      id: warehouse.id,
      archived: false,
    };

    return this.appStore.coreAPIClient
      .put(this.modelRoot, params)
      .then((res) => {
        if (res && res["results"]) {
          warehouse.set(res["results"]);
          this.items.addOrUpdateModel(warehouse);
          warehouse.endUpdate();
        }
      });
  }

  fetchAccountAvailableWh(
    seller_id,
    filters,
    viewName = "default",
    forceRefresh = false
  ) {
    const params = this.adapter.getFiltersUrl(filters);
    const url = `/accounts/${seller_id}/warehouse_accounts${params}`;

    const viewFullName = `${viewName}-${url}-${
      this.appStore && this.appStore.loggedInUserKey
    }`;

    const view = this.view(viewFullName);
    const viewData = this.viewData(viewFullName);

    if (forceRefresh || view.needsUpdate()) {
      view.beginUpdate();

      this.appStore.coreAPIClient
        .sendRequest(
          `/accounts/${seller_id}/warehouse_accounts${params}`,
          "GET"
        )
        .then(
          (res) => {
            view.clear();
            viewData.clear();

            const items = res["results"] || res;
            const contextData = res["headers"] || res;

            if (Array.isArray(items)) {
              for (var i = 0, l = items.length; i < l; i++) {
                view.add(
                  this.items.addOrUpdateModel(
                    new Warehouse(items[i].warehouse, this)
                  )
                );
              }
            } else {
              view.add(
                this.items.addOrUpdateModel(
                  new Warehouse(items.warehouse, this)
                )
              );
            }

            viewData.merge(contextData);

            view.endUpdate();

            return view;
          },
          (err) => {
            view.endUpdate(err);
          }
        );
    }
    return view;
  }
}
