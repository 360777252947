import { Model } from "../lib";
import { computed, action, observable } from "mobx";

import { startCase } from "lodash";

export default class Warehouse extends Model {
  urlRoot = "/warehouses";

  @observable goodsIns;
  @observable stocks;

  constructor(attributes, store) {
    let defaultAttributes = {
      name: null,
      archived: false,
      address: {
        street_name: null,
        street_number: null,
        zip_code: null,
        unit: null,
        floor: null,
        address_line: null,
        line: null,
        city: null,
        accuracy: null,
        lon: null,
        lat: null,
        phone: null,
        country: {
          name: "Argentina",
          code: "AR",
        },
        state: "Ciudad Autónoma de Buenos Aires",
      },
      owner_account: null,
      seller_id: null,
      operation_from_hour: "08:00",
      operation_to_hour: "18:00",
      virtual: false,
    };

    super(attributes, store, defaultAttributes);
  }

  @computed
  get webURI() {
    return `${this._store.baseWebURI}/${this.id}`;
  }

  @computed
  get companyName() {
    return this.owner_account.company_name;
  }

  @computed
  get icon() {
    if (this.type === "showroom") {
      return "warehouse";
    }

    return "itemAdd";
  }

  @computed
  get cookedOperationTimeframe() {
    return `${this.operation_from_hour} a ${this.operation_to_hour} hs`;
  }

  @computed
  get addressRegion() {
    if (this.address.state === this.address.city) {
      return `${startCase(this.address.city.toLowerCase())}`;
    }

    return `${startCase(this.address.city.toLowerCase())}, ${startCase(
      this.address.state.toLowerCase()
    )}`;
  }

  @computed
  get googleMapsCoordinates() {
    if (!this.address) return "";

    return `${this.address.lat}, ${this.address.lon}`;
  }

  @computed
  get cookedStreet() {
    return `${this.address.street_name} ${this.address.street_number}`;
  }

  @computed
  get cookedFloor() {
    return `${this.address.floor || ""} ${" "}${this.address.unit || ""}`;
  }

  @computed
  get cookedAddress() {
    if (this.cookedFloor) return `${this.cookedStreet}, ${this.cookedFloor}`;

    return `${this.cookedStreet}`;
  }

  @computed
  get cookedState() {
    return `${this.address.city}, ${this.address.state}`;
  }
  @computed
  get isSelected() {
    return this._store.appStore.ui.lists.selectedWarehouses.find(
      (item) => item.id === this.id
    );
  }
  @computed
  get addressSummary() {
    if (
      !this.address ||
      !this.address.address_line ||
      !this.address.street_name ||
      !this.address.street_number ||
      !this.address.city
    ) {
      return "";
    }
    if (this.address.address_line) {
      return `${this.address.address_line}, ${this.address.city}`;
    } else {
      return `${this.address.street_name || ""} ${
        this.address.street_number ? `${this.address.street_number},` : ""
      } ${this.address.unit ? `${this.address.unit},` : ""} ${
        this.address.city
      }`;
    }
  }

  setAddressLonLat(lon, lat) {
    this.address.lonlat = `POINT(${lon} ${lat})`;
  }

  @action
  fetchStocksStats() {
    if (this.stocks) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.warehouses.searchStockStasts(this.id, {}).andThen(
        (res) => {
          this.stocks = res;

          this.endUpdate();
        },
        (err) => {
          this.endUpdate();
        }
      );
    }
    return this;
  }
}
