import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button, Text } from "shipnow-mercurio";
import { withToastManager } from "react-toast-notifications";

class ToastWithButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick && this.props.onClick();

    if (this.props.closeAfterClick) {
      this.removeToast();
    }
  }

  removeToast() {
    const toasts = this.props.toastManager.toasts;
    const lastToastId = toasts.length > 0 ? toasts[toasts.length - 1].id : null;

    this.props.toastManager.remove(lastToastId);
  }

  render() {
    const { title, buttonText } = this.props;

    return (
      <React.Fragment>
        <Text className="mb-2" multiline>
          {title}
        </Text>

        <Button color="highlight" onClick={this.handleClick} type="outlined">
          {buttonText}
        </Button>
      </React.Fragment>
    );
  }
}

ToastWithButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.object,
  closeAfterClick: PropTypes.bool,
};

ToastWithButton.defaultProps = {
  title: "Hello world!",
  onClick: null,
  buttonText: "Click Me!",
  closeAfterClick: false,
};

export default withToastManager(ToastWithButton);
